import { useContext } from "react";
import OnboardingContext from "../../../store/onboardingContext";
import BlueBtn from "../../UI/BlueBtn";
import TransparentBtn from "../../UI/TransparentBtn";

export function ThirdStepBtns() {
  const OnboardingCtx = useContext(OnboardingContext);

  const goBackHandler = () => {
    OnboardingCtx.setOnboardingStep(1);
  };

  const nextHandler = () => {
    try {
      OnboardingCtx.setOnboardingStep(3);
    } catch (err: any) {}
  };

  return (
    <div className="grid grid-cols-2 gap-x-4 mb-4">
      <TransparentBtn
        title="Go back"
        className="font-normal"
        onClick={goBackHandler}
      />
      <BlueBtn title="Continue" onClick={nextHandler} />
    </div>
  );
}
