import axios from "axios";
import { API_CONFIG } from "../constants";

export interface GetCalendarInfoOutput {
  adminName: string;
  calendarName: string;
  meetingPriceInDollars: string;
  walletId: string;
  meetingLink: string;
  availableFirst: string;
  availableLast: string;
  meetingDuration: number;
}

export async function getCalendarInfo(
  adminName: string
): Promise<GetCalendarInfoOutput> {
  return (await axios.get(`${API_CONFIG.URL}/getCalendarInfo/${adminName}`)).data;
}
