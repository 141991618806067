import { Fragment, useContext } from "react";
import { ethers } from "ethers";

import { getUniqueAdminName } from "../../../Api/getUniqueAdminName";
import { useAnalytics } from "../../../hooks/useAnalytics";
import OnboardingContext from "../../../store/onboardingContext";
import { getLocalStorage } from "../../functions/localStorage/getLocalStorage";
import BlueBtn from "../../UI/BlueBtn";

export function FirstStepBtn() {
  const OnboardingCtx = useContext(OnboardingContext);

  const isEthereumAddressValid = (address: string | "") => {
    try {
      //this statement will throw if invalid address is provided
      //if the address has both uppercase and lowercase letters it will check for checksum
      //if the address is fully lowercase then it will generate a checksum-ed address
      const newAddress = ethers.utils.getAddress(address);
      if (OnboardingCtx.walletAddress !== newAddress)
        OnboardingCtx.setWalletAddress(newAddress);
      return "OK";
    } catch (err: any) {
      if (err.code && err.code === "INVALID_ARGUMENT") {
        OnboardingCtx.setWalletIdValidation("Invalid ethereum address");
      }
    }
  };

  const nextHandler = async () => {
    try {
      if (OnboardingCtx.adminName.trim().length === 0) {
        OnboardingCtx.setError("Name can not be empty");
        return;
      } else if (isEthereumAddressValid(OnboardingCtx.walletAddress)) {
        if (OnboardingCtx.error) OnboardingCtx.setError("");
        OnboardingCtx.setAdminName(OnboardingCtx.adminName.trim());

        const checkUniqueAdminName = await getUniqueAdminName(
          getLocalStorage("adminEmail") || "",
          OnboardingCtx.adminName
        );

        if (checkUniqueAdminName.adminName.length === 0) {
        } else {
          OnboardingCtx.setError(
            `Name is taken. Try another - ${checkUniqueAdminName.adminName} for example`
          );
          return;
        }

        OnboardingCtx.setOnboardingStep(1);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useAnalytics("/onboarding/2");

  return (
    <Fragment>
      <div className="grid grid-cols-1 mt-4">
        <BlueBtn title="Continue" onClick={nextHandler} />
      </div>
    </Fragment>
  );
}
