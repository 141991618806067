import { Fragment } from "react";
import { initGoogleAuthorization } from "../../functions/googleCommunication";

import googleCalendarICon from "../../../assets/google-calendar-icon.png";
import { authorization } from "../../../Api/authorization";
import { useAnalytics } from "../../../hooks/useAnalytics";

declare var google: any;

export function FourthStep() {
  useAnalytics("/onboarding/4");

  const authorizeUserToGoogleCalendar = () => {
    try {
      const client = initGoogleAuthorization(
        google,
        "https://www.googleapis.com/auth/calendar.readonly",
        (googleResponse: any) => {
          if (googleResponse.prompt === "consent") {
            document.getElementById("registerUserBtn")!.click();
            authorization(googleResponse);
          }
        },
        localStorage.getItem("adminEmail")!
      );
      client.requestCode();
    } catch (Err) {}
  };

  return (
    <Fragment>
      <div className="w-[90%] pb-12 mx-auto">
        <div className="font-poppins text-[#000000] font-semibold text-[13px] w-full my-[1%]">
          <div className="w-full ">
            <h1 className="text-[20px] mb-4">Connect your calendar</h1>
            <p className="font-normal text-[14px]">
              Connect your calendar to auto-check for busy times and add new
              events as they are scheduled.
            </p>
            <div className="h-[1px] w-full my-3 bg-[#b6b6b6]"></div>
          </div>
        </div>
        <div className="flex w-full justify-between gap-x-[5%] mt-[20px]">
          <div className="flex flex-col xs:gap-y-2">
            <i className="text-red-400 bi bi-google"></i>
            <p>Google</p>
          </div>
          <button
            type="button"
            onClick={authorizeUserToGoogleCalendar}
            className="flex flex-1 justify-between  border-[0.1px] border-b-[1px] border-[#d6d6d6]  shadow-md px-[10px] py-[8px] hover:bg-[#97999b] group transition duration-500 min-w-[190px]"
          >
            <div>
              <div className="flex gap-x-4">
                <img
                  className="w-[22px] h-[22px] mt-[4px]"
                  alt="Google sign-in"
                  src={googleCalendarICon}
                />
                <span className="font-semibold text-[#818181] mr-[9.5px] ml-[-3px] group-hover:text-white whitespace-nowrap">
                  Google Calendar
                </span>
              </div>
              <span className="group-hover:text-white text-[#818181] text-[13px] ml-[-16px]">
                Gmail, G Suite
              </span>
            </div>
            <i className="bi bi-caret-right-fill"></i>{" "}
          </button>
        </div>
      </div>
    </Fragment>
  );
}
