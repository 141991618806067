import { MouseEvent } from "react";
import Card from "../../UI/Card";

interface button {
  text: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export function HeaderBtn({ text, onClick }: button) {
  return (
    <div className="group top-0 p-0">
      <Card>
        <h1 className="text-[#000000bb] text-[18px] font-semibold ">
          <button onClick={onClick}>
            <span className="text-[18px] font-normal hover:opacity-[0.5] group-hover:animate-gt">
              {text}
            </span>
          </button>
        </h1>
      </Card>
    </div>
  );
}
