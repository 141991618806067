import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Update } from "./ActivityDescriptionSC/Update";
import { ActivityDescriptionProps } from "../interfaces/ActivityDescriptionProps";
import CalendarInfoContext from "../../store/calendarInformationContext";
import { dayNames, FRONTEND_CONFIG, monthNames } from "../../constants";
import { addLeadingZeroToTime } from "../functions/addLeadingZeroToTime";
import { getTokenData } from "../functions/getTokenData";
import { getLocalStorage } from "../functions/localStorage/getLocalStorage";

const options = [
  { value: "30", label: "30 Minutes" },
  { value: "60", label: "60 Minutes" },
  { value: "90", label: "90 Minutes" },
];

export function ActivityDescription({
  mode,
  admin,
  choosenDate,
}: ActivityDescriptionProps) {
  const navigate = useNavigate();
  const calendarInfoCtx = useContext(CalendarInfoContext);
  const handleMeetingDuration = (selectedOption: any) => {
    calendarInfoCtx.updateCalendarInformationValue(
      parseInt(selectedOption.value),
      "meetingDuration"
    );
  };

  return (
    <div
      className={`flex flex-col p-6 w-full gap-1 sm:whitespace-nowrap lg:whitespace-normal ${
        admin
          ? "w-[40%]"
          : "lg:border-r-[2px] flex-[4_1_] border-[#7c7c7c62] h-full overflow-hidden"
      }`}
    >
      {mode === "form" && (
        <button
          className="rounded-[50%] h-[40px] w-[40px] drop-shadow-xl shadow-[1px_1px_10px_0px_rgba(0,0,0,0.3)] mb-[4%] flex items-center justify-center hover:bg-[#afadadab] transition duration-500"
          onClick={() => navigate(-1)}
        >
          <i className="bi bi-arrow-left flex-items-center "></i>
        </button>
      )}
      {mode === "intro" &&
        admin &&
        calendarInfoCtx.calendarInformation.adminName.isEditable && (
          <Update
            updateDescriptionState={(mode = "forbid") =>
              calendarInfoCtx.updateCalendarInformationState(mode, "adminName")
            }
            updateDescriptionValueHandler={(e: any) =>
              calendarInfoCtx.updateCalendarInformationValue(e, "adminName")
            }
            text={calendarInfoCtx.calendarInformation.adminName.value}
            validator={(val) => {
              if (!val.trim()) return "Input can't be empty";
              return "";
            }}
          />
        )}
      {!calendarInfoCtx.calendarInformation.adminName.isEditable && (
        <div className="flex items-center gap-x-2 group hover:opacity-[0.75] transition duration-500 cursor-pointer">
          <p className="text-[#666464ec]  text-[16px]] font-semibold text-center lg:text-left">
            {calendarInfoCtx.calendarInformation.adminName.value}
          </p>
        </div>
      )}
      {mode === "intro" &&
        admin &&
        calendarInfoCtx.calendarInformation.calendarName.isEditable && (
          <Update
            updateDescriptionState={(mode = "forbid") =>
              calendarInfoCtx.updateCalendarInformationState(
                mode,
                "calendarName"
              )
            }
            updateDescriptionValueHandler={(e) =>
              calendarInfoCtx.updateCalendarInformationValue(e, "calendarName")
            }
            text={calendarInfoCtx.calendarInformation.calendarName.value}
            validator={(val) => {
              if (!val.trim()) return "Input can't be empty";
              return "";
            }}
          />
        )}
      {!calendarInfoCtx.calendarInformation.calendarName.isEditable && (
        <div className="flex items-center gap-x-2 group hover:opacity-[0.75] transition duration-500 cursor-pointer">
          <h1 className="text-[28px] font-bold text-center lg:text-left">
            {calendarInfoCtx.calendarInformation.calendarName.value}
          </h1>

          {admin && (
            <i
              className="bi bi-pencil w-[16px] h-[23px] group-hover:opacity-[0.75] transition duration-500 "
              onClick={() => {
                if (mode === "intro" && admin) {
                  calendarInfoCtx.updateCalendarInformationState(
                    "allow",
                    "calendarName"
                  );
                }
              }}
            />
          )}
        </div>
      )}
      <div className="text-[#666464] text-[16px] font-semibold text-center lg:text-left flex items-center">
        ⏰{" "}
        {!calendarInfoCtx.calendarInformation.meetingDuration.isEditable ? (
          <span className="ml-2">
            {calendarInfoCtx.calendarInformation.meetingDuration.value || ""}
          </span>
        ) : (
          <div className="flex gap-x-2 ml-3">
            <div className="w-[200px]">
              <Select options={options} onChange={handleMeetingDuration} />
            </div>
            <button
              className="p-[2px] px-[5%] bg-[#444443] text-[#bfbfbf] hover:bg-[#5d5d5b] hover:text-[#ffffff] transition duration-500 rounded-xl text-[15px]"
              onClick={() => {
                calendarInfoCtx.updateCalendarInformationState(
                  "forbid",
                  "meetingDuration"
                );
                calendarInfoCtx.sendCalendarInformation();
              }}
            >
              Update
            </button>
          </div>
        )}
        {!calendarInfoCtx.calendarInformation.meetingDuration.isEditable && (
          <span className="ml-1">Minutes </span>
        )}
        {!calendarInfoCtx.calendarInformation.meetingDuration.isEditable &&
          admin && (
            <i
              className="bi bi-pencil w-[16px] h-[24px] hover:opacity-[0.75] transition duration-500 ml-2 cursor-pointer "
              onClick={() => {
                calendarInfoCtx.updateCalendarInformationState(
                  "allow",
                  "meetingDuration"
                );
              }}
            />
          )}
      </div>
      <div className="text-center lg:text-left text-[#1d1c1c] text-[16px] inline cursor-pointer">
        {mode === "intro" &&
          admin &&
          calendarInfoCtx.calendarInformation.meetingLink.isEditable && (
            <Update
              updateDescriptionState={(mode = "forbid") =>
                calendarInfoCtx.updateCalendarInformationState(
                  mode,
                  "meetingLink"
                )
              }
              updateDescriptionValueHandler={(e: any) =>
                calendarInfoCtx.updateCalendarInformationValue(e, "meetingLink")
              }
              text={calendarInfoCtx.calendarInformation.meetingLink.value}
              validator={(val) => {
                if (!val.trim()) return "Input can't be empty";
                return "";
              }}
            />
          )}
        {admin && !calendarInfoCtx.calendarInformation.meetingLink.isEditable && (
          <div className="flex items-center gap-x-2">
            <span
              className="text-blue-400 hover:underline hover:opacity-[0.75] transition duration-150 cursor-pointer whitespace-nowrap"
              onClick={() => {
                navigator.clipboard.writeText(
                  calendarInfoCtx.calendarInformation.meetingLink.value
                );
              }}
            >
              {calendarInfoCtx.calendarInformation.meetingLink.value}
            </span>
            {admin && (
              <i
                className="bi bi-pencil w-[16px] h-[26px] hover:opacity-[0.75] transition duration-500 "
                onClick={() => {
                  if (mode === "intro" && admin) {
                    calendarInfoCtx.updateCalendarInformationState(
                      "allow",
                      "meetingLink"
                    );
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      {mode === "intro" &&
        admin &&
        calendarInfoCtx.calendarInformation.meetingPriceInDollars
          .isEditable && (
          <Update
            updateDescriptionState={(mode = "forbid") =>
              calendarInfoCtx.updateCalendarInformationState(
                mode,
                "meetingPriceInDollars"
              )
            }
            updateDescriptionValueHandler={(e) =>
              calendarInfoCtx.updateCalendarInformationValue(
                e,
                "meetingPriceInDollars"
              )
            }
            text={
              calendarInfoCtx.calendarInformation.meetingPriceInDollars.value
            }
            validator={(val) => {
              if (!val.trim() || !/[0-9]/.test(val))
                return "Amount must be a valid number";
              if (val[0] === "0" && val !== "0")
                return "Amount must not start with leading zeros";
              return "";
            }}
            inputType="number"
          />
        )}
      {choosenDate && (
        <div className="text-[#666464ec] text-[16px] font-semibold">
          {addLeadingZeroToTime(`${choosenDate?.getHours()}`)}:
          {addLeadingZeroToTime(`${choosenDate?.getMinutes()}`)},{" "}
          {dayNames[choosenDate?.getDay()]},{" "}
          {monthNames[choosenDate?.getMonth() || 0]} {choosenDate?.getDate()},{" "}
          {choosenDate?.getFullYear()}
        </div>
      )}
      {mode === "intro" &&
        !calendarInfoCtx.calendarInformation.meetingPriceInDollars
          .isEditable && (
          <div className="flex items-center gap-x-2 group hover:opacity-[0.75] transition duration-500 cursor-pointer">
            <p className="text-[#666464ec] text-[16px] font-semibold text-center lg:text-left whitespace-nowrap">
              <span className="text-black text-[15px]">Event price:</span>
              <span className="text-green-700 font-bold ml-[4px] mr-[3px]">
                $
              </span>
              {calendarInfoCtx.calendarInformation.meetingPriceInDollars.value}
            </p>
            {admin && (
              <i
                className="bi bi-pencil  w-[16px] h-[26px] group-hover:opacity-[0.75] transition duration-500 "
                onClick={() => {
                  if (mode === "intro" && admin) {
                    calendarInfoCtx.updateCalendarInformationState(
                      "allow",
                      "meetingPriceInDollars"
                    );
                  }
                }}
              />
            )}
          </div>
        )}
      {mode === "intro" &&
        admin &&
        calendarInfoCtx.calendarInformation.walletId.isEditable && (
          <Update
            updateDescriptionState={(mode = "forbid") =>
              calendarInfoCtx.updateCalendarInformationState(mode, "walletId")
            }
            updateDescriptionValueHandler={(e: any) =>
              calendarInfoCtx.updateCalendarInformationValue(e, "walletId")
            }
            text={calendarInfoCtx.calendarInformation.walletId.value}
            validator={(val) => {
              if (!val.trim()) return "Input can't be empty";
              return "";
            }}
          />
        )}
      {mode === "intro" &&
        admin &&
        !calendarInfoCtx.calendarInformation.walletId.isEditable && (
          <div className="flex items-center gap-x-2 group hover:opacity-[0.75] transition duration-500 cursor-pointer">
            <p
              className="text-[#666464ec] text-[16px] font-semibold text-center lg:text-left"
              onClick={() => {
                navigator.clipboard.writeText(
                  calendarInfoCtx.calendarInformation.walletId.value
                );
              }}
            >
              {calendarInfoCtx.calendarInformation?.walletId?.value?.substring(
                0,
                12
              )}
              ...
              {calendarInfoCtx.calendarInformation?.walletId?.value?.substring(
                30,
                43
              )}
            </p>
            {admin && (
              <i
                className="bi bi-pencil w-[16px] h-[24px] group-hover:opacity-[0.75] transition duration-500"
                onClick={() => {
                  if (mode === "intro" && admin) {
                    calendarInfoCtx.updateCalendarInformationState(
                      "allow",
                      "walletId"
                    );
                  }
                }}
              />
            )}
          </div>
        )}
    </div>
  );
}
