import axios from "axios";
import { API_CONFIG } from "../constants";

export async function getSlots(adminName: string | undefined, dateTime: Date) {
  const token = localStorage.getItem("NT_TOKEN") || "";

  return (
    await axios.post(
      `${API_CONFIG.URL}/getSlots`,
      {
        adminName,
        date: dateTime.getDate(),
        requesterTimeZoneOffset: dateTime.getTimezoneOffset(),
      },
      {
        headers: { "X-Auth-Token": token },
      }
    )
  ).data;
}
