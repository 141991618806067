import { createContext, FC, ReactNode, SetStateAction, useState } from "react";
import { ITimezoneOption } from "react-timezone-select";

const emptyTimezoneObject = {
  abbrev: "",
  altName: "",
  label: "",
  offset: 0,
  value: "",
};

interface OnboardingContextInterface {
  adminName: string;
  calendarName: string;
  meetingLink: string;
  meetingPriceInDollars: number;
  walletAddress: string;
  availableFirst: string;
  availableLast: string;
  onboardingStep: number;
  selectedTimezone: ITimezoneOption;
  meetingDuration: number;
  googleCalendarResponse: any;
  meetingPriceValidation: string;
  error: string;
  walletIdValidation: string;

  setAdminName: (arg: SetStateAction<string>) => void;
  setCalendarName: (arg: SetStateAction<string>) => void;
  setMeetingLink: (arg: SetStateAction<string>) => void;
  setMeetingPriceInDollars: (arg: SetStateAction<number>) => void;
  setWalletAddress: (arg: SetStateAction<string>) => void;
  setAvailableFirst: (arg: SetStateAction<string>) => void;
  setAvailableLast: (arg: SetStateAction<string>) => void;
  setOnboardingStep: (arg: SetStateAction<number>) => void;
  setSelectedTimezone: (arg: SetStateAction<ITimezoneOption>) => void;
  setMeetingDuration: (arg: SetStateAction<number>) => void;
  setGoogleCalendarResponse: (arg: SetStateAction<any>) => void;
  setMeetingPriceValidation: (arg: SetStateAction<string>) => void;
  setError: (arg: SetStateAction<string>) => void;
  setWalletIdValidation: (arg: SetStateAction<string>) => void;
  reset: () => void;
}
const OnboardingContext = createContext<OnboardingContextInterface>({
  adminName: "",
  calendarName: "",
  meetingLink: "",
  meetingPriceInDollars: 0,
  walletAddress: "",
  availableFirst: "",
  availableLast: "",
  onboardingStep: 0,
  selectedTimezone: emptyTimezoneObject,
  meetingDuration: 30,
  googleCalendarResponse: {},
  meetingPriceValidation: "",
  error: "",
  walletIdValidation: "",

  setAdminName: (arg: SetStateAction<string>) => {},
  setCalendarName: (arg: SetStateAction<string>) => {},
  setMeetingLink: (arg: SetStateAction<string>) => {},
  setMeetingPriceInDollars: (arg: SetStateAction<number>) => {},
  setWalletAddress: (arg: SetStateAction<string>) => {},
  setAvailableFirst: (arg: SetStateAction<string>) => {},
  setAvailableLast: (arg: SetStateAction<string>) => {},
  setOnboardingStep: (arg: SetStateAction<number>) => {},
  setSelectedTimezone: (arg: any) => {},
  setMeetingDuration: (arg: SetStateAction<number>) => {},
  setGoogleCalendarResponse: (arg: SetStateAction<number>) => {},
  setMeetingPriceValidation: (arg: SetStateAction<string>) => {},
  setError: (arg: SetStateAction<string>) => {},
  setWalletIdValidation: (arg: SetStateAction<string>) => {},
  reset: () => {},
});

type Props = {
  children?: ReactNode;
};

export const OnboardingContextProvider: FC<Props> = ({ children }: Props) => {
  const [adminName, setAdminName] = useState("");
  const [calendarName, setCalendarName] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [meetingPriceInDollars, setMeetingPriceInDollars] = useState(0);
  const [availableFirst, setAvailableFirst] = useState("00:00");
  const [availableLast, setAvailableLast] = useState("23:30");
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [selectedTimezone, setSelectedTimezone] =
    useState<ITimezoneOption>(emptyTimezoneObject);
  const [meetingDuration, setMeetingDuration] = useState(30);
  useState("");
  const [googleCalendarResponse, setGoogleCalendarResponse] = useState<any>();
  const [meetingPriceValidation, setMeetingPriceValidation] = useState("");
  const [error, setError] = useState("");
  const [walletIdValidation, setWalletIdValidation] = useState("");

  const reset = () => {
    setAdminName("");
    setCalendarName("");
    setMeetingLink("");
    setWalletAddress("");
    setMeetingPriceInDollars(0);
    setAvailableFirst("");
    setAvailableLast("");
    setSelectedTimezone(selectedTimezone);
    setMeetingDuration(30);
    setOnboardingStep(0);
    setGoogleCalendarResponse({});
  };
  return (
    <OnboardingContext.Provider
      value={{
        adminName,
        calendarName,
        meetingLink,
        meetingPriceInDollars,
        walletAddress,
        availableFirst,
        availableLast,
        onboardingStep,
        selectedTimezone,
        meetingDuration,
        googleCalendarResponse,
        meetingPriceValidation,
        error,
        walletIdValidation,

        setAdminName,
        setCalendarName,
        setMeetingLink,
        setMeetingPriceInDollars,
        setWalletAddress,
        setAvailableFirst,
        setAvailableLast,
        setOnboardingStep,
        setSelectedTimezone,
        setMeetingDuration,
        setGoogleCalendarResponse,
        setMeetingPriceValidation,
        setError,
        setWalletIdValidation,
        reset,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContext;
