import { Link } from "react-router-dom";

/**
 * TryForFree sub component, navigates to signin page
 * @returns
 */
export function TryForFree() {
  return (
      <div className="flex items-center mt-4 md:mt-2">
        <Link
          className="mx-auto rounded-[30px] py-[20px] bg-blue-600 px-[40px] text-white hover:bg-blue-400 font-bold duration-500"
          to={"/signin"}
        >
          Sign up for free
        </Link>
      </div>
  );
}
