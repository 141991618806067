import { ethers } from "ethers";
import { USDC_CONTRACT_CODE } from "../../constants";
import { ERC20_GENERIC_ABI } from "./abi/ERC20ABI";
import { checkWebProviderAndConnect } from "./makeTransactionEther";

const sendUSDC = async (recipient: string, amount: number) => {
  await checkWebProviderAndConnect();

  const provider = new ethers.providers.Web3Provider(window?.ethereum);
  const signer = provider.getSigner();

  const usdcContract = new ethers.Contract(
    USDC_CONTRACT_CODE,
    ERC20_GENERIC_ABI,
    provider
  );

  const tx = await usdcContract
    .connect(signer)
    .transfer(recipient, amount);
    
  const body = { hash: tx?.hash, address: tx?.from };
  return body;
};

export { sendUSDC };
