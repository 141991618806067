import { Fragment, RefObject } from "react";
export interface FormInputsProps {
  errorMessage: string;
  userNameRef: RefObject<HTMLInputElement>;
  emailRef: RefObject<HTMLInputElement>;
  extraInformationRef: RefObject<HTMLTextAreaElement>;
}

export function FormInputs({
  errorMessage,
  userNameRef,
  emailRef,
  extraInformationRef,
}: FormInputsProps) {
  return (
    <Fragment>
      <input
        type="text"
        placeholder="Name"
        required
        ref={userNameRef}
        id="name"
        className="input-nt w-full md:w-[60%] sm:mx-auto lg:mx-0"
      />
      {errorMessage && <p className="text-red-600">{errorMessage}</p>}
      <input
        type="email"
        placeholder="Email"
        required
        ref={emailRef}
        id="email"
        className="input-nt w-full md:w-[60%] sm:mx-auto lg:mx-0"
      />
      <textarea
        placeholder="Please share additional information"
        ref={extraInformationRef}
        autoComplete="off"
        className="input-nt w-full md:w-[60%] min-h-[50px] h-[80px] max-h-[200px] sm:mx-auto lg:mx-0"
      ></textarea>
    </Fragment>
  );
}
