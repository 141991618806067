import { getTokenData } from "./getTokenData";
/**
 * check whether user should be put on admin mode
 * @param adminName adminName associated with calendar page
 * @returns
 */
export function isAdmin(adminName: string): boolean {
  const token = localStorage.getItem("NT_TOKEN");
  if (!token) return false;
  return getTokenData(token).adminName === adminName;
}
