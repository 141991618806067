import axios from "axios";
import { API_CONFIG } from "../constants";

export interface contactUsInput {
  authorFullname: string;
  authorEmail: string;
  authorMessage: string;
}

export async function contactUsAPI(input: contactUsInput) {
  return (await axios.post(`${API_CONFIG.URL}/contactUs`, input)).data;
}
