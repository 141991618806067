import { SetStateAction } from "react";
import { getEtherPrice } from "./getEtherPrice";
import { CalendarInfoContextInterface } from "../../store/calendarInformationContext";

export const setEtherPrice = async (
  adminName: string | undefined,
  setEthPrice: (arg: SetStateAction<string>) => void,
  calendarInfoCtx: CalendarInfoContextInterface
) => {
  if (calendarInfoCtx.calendarInformation.meetingPriceInDollars.value) {
    const ETH_FLUCTUATION = await getEtherPrice();
    const fluctuation = parseFloat(ETH_FLUCTUATION);
    const meetingPriceInDollars = parseInt(
      calendarInfoCtx.calendarInformation.meetingPriceInDollars.value
    );
    const newPrice = (fluctuation * meetingPriceInDollars).toFixed(10);
    setEthPrice(newPrice.toString());
  }
};
