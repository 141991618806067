import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";

const metamaskConnect = async () => {
  try {
    await window?.ethereum?.enable();
    const accounts = await window?.ethereum?.request({
      method: "eth_requestAccounts",
    });

    return accounts[0];
  } catch (error: any) {
    if (error?.code === 4001) {
      // If this happens, the user rejected the connection request.
      toast.error("Rejected Request, Please connect to MetaMask.");
    } else if (error?.code === -32002) {
      toast.error("User Request Pending, Please unlock MetaMask and try agin.");
    } else {
      console.log(error);
    }

    return null;
  }
};

const checkWebProviderAndConnect = async () => {
  try {
    const provider = await detectEthereumProvider();

    if (!provider) {
      toast.error("Wallet not found. Please install MetaMask.");
    } else {
      const tempAddress = await metamaskConnect();
      return tempAddress;
    }
  } catch (error: any) {
    toast.error(
      error?.message || "Something went wrong while connecting to wallet."
    );
  }
};

const sendTx = async (recipient: string, amount: number) => {
  try {
    const address = await checkWebProviderAndConnect();

    const provider = new ethers.providers.Web3Provider(window?.ethereum);
    const signer = provider.getSigner();

    ethers.utils.getAddress("0xeb40aad49E2C9Baa9a2829a06Fbf50f319Bbe32D");

    const tx = await signer.sendTransaction({
      to: recipient,
      value: ethers.utils.parseEther(`${amount}`),
      gasLimit: 50000,
    });
    const body = {
      hash: tx?.hash,
      address,
    };
    return body;
  } catch (err) {
    console.log(err);
  }
};

const getTx = async (hash: any) => {
  checkWebProviderAndConnect();
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const tx = await provider.getTransaction(hash);
  const returnBody = { confirmations: tx.confirmations, chainId: tx.chainId };
  return returnBody;
};
export { sendTx, checkWebProviderAndConnect, metamaskConnect, getTx };
