// should ONLY be used for token returned by login
import decoder from "jwt-decode";
export interface GetTokenDataOutput {
  email: string;
  name: string;
  adminName: string;
}
/**
 * get email and name from jwt token
 * @param token token obtained from backend
 * @returns
 */
export function getTokenData(token: string): GetTokenDataOutput {
  try {
    const data = decoder(token);
    return decoder(token) as GetTokenDataOutput;
  } catch (e) {
    // console.trace();
    return { email: "", adminName: "", name: "" };
  }
  //return decoder(token) as GetTokenDataOutput;
}
