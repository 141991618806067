import axios from "axios";
import { API_CONFIG } from "../constants";

export interface MakeReservationInput {
  adminName: string | undefined;
  userName: string;
  userEmail: string; 
  extraInformation: string;
  dateTime: string;
  meetingDuration: number;
  transactionID: string;
  userTimeOffset: number;
}

export interface MakeReservationOutput {
  status: "success" | "error";
}
export async function makeReservation(
  input: MakeReservationInput,
  token?: string
): Promise<MakeReservationOutput> {
  return (
    await axios.post(
      `${API_CONFIG.URL}/makeReservation`,
      input,
      token
        ? {
            headers: { "X-Auth-Token": token },
          }
        : {}
    )
  ).data;
}
