import axios from "axios";
import { API_CONFIG } from "../constants";

export interface GetMyTimezoneOutput {
  timeZoneOffset: number;
}

export async function getMyTimezone(
  adminName: string
): Promise<GetMyTimezoneOutput> {
  return (await axios.get(`${API_CONFIG.URL}/getUserTimezone/${adminName}`))
    .data;
}
