import { useState, useEffect, RefObject, Fragment } from "react";
import {
  ReservationAdminItem,
  reservationsAdmin,
} from "../../../Api/reservations";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../UI/Spinner";
import Card from "../../UI/Card";
import { getMyTimezone } from "../../../Api/getMyTimezone";
import { getLocalStorage } from "../../functions/localStorage/getLocalStorage";
import { getTokenData } from "../../functions/getTokenData";
import { EventEntry } from "./EventEntry";
import { groupedMeetings } from "../../functions/groupedMeetings";
import { pastReservationsAdmin } from "../../../Api/getPastReservations";
import { MeetingsProps } from "../../interfaces/MeetingsProps";

export function Meetings({ meet }: MeetingsProps) {
  const [reservations, setReservations] = useState<
    ReservationAdminItem[] | null
  >(null); // reservation array
  const [userTimeZoneOffset, setUserTimeZoneOffset] = useState<number>(0);
  const navigate = useNavigate();
  const [currentRef, setCurrentRef] =
    useState<RefObject<HTMLDivElement> | null>(null);

  useEffect(() => {
    try {
      const setTimeZoneOffsetHandler = async () => {
        const { timeZoneOffset } = await getMyTimezone(
          getTokenData(getLocalStorage("NT_TOKEN")!).adminName
        );

        setUserTimeZoneOffset(timeZoneOffset);
      };
      setTimeZoneOffsetHandler();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("NT_TOKEN");
    if (!token) {
      navigate("/");
      return;
    }
    const meetings = async () => {
      try {
        const data =
          meet === "upcomingMeetings"
            ? await reservationsAdmin(token)
            : await pastReservationsAdmin(token);
        let info =
          data &&
          data.sort((x, y) => {
            return new Date(x.dateTime).getTime() <
              new Date(y.dateTime).getTime()
              ? 1
              : -1;
          }) &&
          data.filter((element, index) => {
            if (data.length > 1 && index > 0) {
              if (data[index].dateTime === data[index - 1].dateTime) {
                return false;
              }
            }
            return true;
          });
        info = groupedMeetings(info);
        setReservations(info);
      } catch (err) {
        console.log(err);
      }
    };
    meetings();
  }, []);

  return (
    <div className="flex flex-col items-center gap-2 mx-auto lg:max-w-[630px] mt-[2.5%]  mb-4">
      <Card className="shadow-[2px_2px_20px_-5px_rgba(0,0,0,0.7)] w-[90%] lg:w-full mt-[30px] lg:mt-0 ">
        <div className="flex flex-col items-center gap-2 mx-auto lg:max-w-[600px] rounded-[12px] p-4 text-center  ">
          {reservations === null && <Spinner styleSize="w-20 h-20" />}
          {reservations &&
            Object.entries(reservations).map((key, value) => {
              return (
                <div className="flex flex-col items-center gap-2  w-[90%] lg:w-full  lg:mt-0 rounded-[10px]">
                  <p className="flex w-full justify-between   bg-stone-200 rounded-[10px] ">
                    <div className="text-md font-semibold text-teal-700  p-2  ">
                      {key[0]}
                    </div>
                  </p>
                  {Object.entries(key[1]).map((xKey, xValue) => {
                    return (
                      <Fragment key={xKey[1].dateTime}>
                        <EventEntry
                          item={xKey[1]}
                          userTimeZoneOffset={userTimeZoneOffset}
                          currentRef={currentRef}
                          setCurrentRef={setCurrentRef}
                        />
                      </Fragment>
                    );
                  })}
                  <hr className=" grid border-solid w-full border-gray-300 gap-y-2" />
                </div>
              );
            })}
          {reservations !== null && Object.keys(reservations).length === 0 && (
            <p className="text-xl">No past meetings</p>
          )}
        </div>
      </Card>
    </div>
  );
}
