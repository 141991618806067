import { NavigateFunction } from "react-router-dom";
import { checkLoginVerificationCode } from "../../Api/checkVerificationCode";
import { setLocalStorage } from "./localStorage/setLocalStorage";

export const checkVerificationCodeAndLogin = async (
  userEmail: string,
  verificationCode: string,
  navigate: NavigateFunction,
  setIsCodeCorrect: (param: boolean) => void
) => {
  try {
    if (verificationCode.length === 4) {
      let serverResponse = await checkLoginVerificationCode({
        email: userEmail,
        verificationCode,
      });
      if (serverResponse.status === "success") {
        setIsCodeCorrect(true);

        setLocalStorage("NT_TOKEN", serverResponse?.token);
        setLocalStorage("adminEmail", serverResponse?.email);

        if (serverResponse.finishedOnboarding) {
          navigate(`/${serverResponse?.adminName}`);
        } else {
          navigate(`/onboarding`);
        }
      } else {
        setIsCodeCorrect(false);
      }
    }
  } catch (Err) {}
};
