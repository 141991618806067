import { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import { ActivityDescription } from "../subComponents/ActivityDescription";
import { IntroProps } from "../interfaces/IntroProps";
import { Link, useParams } from "react-router-dom";
import { isAdmin } from "../functions/isAdmin";
import { getAllSlotsForDate } from "../functions/getAllSlotsForDate";
import CalendarInfoContext from "../../store/calendarInformationContext";
import { Spinner } from "../UI/Spinner";
import { updateGoogleCalendarBookings } from "../../Api/updateGoogleCalendarBookings";
import Header from "../subComponents/Buttons/Header";
import { useAnalytics } from "../../hooks/useAnalytics";
import { getTokenData } from "../functions/getTokenData";
import { getLocalStorage } from "../functions/localStorage/getLocalStorage";
import { NotFoundWrapper } from "../wrappers/NotFoundWrapper";
import { HoursGuestViewMain } from "../subComponents/IntroSC/HoursGuestViewMain";
import { GoToMetamaskBrowser } from "../subComponents/IntroSC/GoToMetamaskBrowser";
import { FRONTEND_CONFIG, METAMASK_DEEP_LINK } from "../../constants";
import SearchBar from "../UI/SearchBar";
import { metaPixelLandedOnIntroPage } from "../functions/metaPixelEvents";
import BlueBtn from "../UI/BlueBtn";

/**
 * Calendar page component
 * @param param getter and setter for choosenDate variable, which is managed outside of this component
 * @returns
 */
export function Intro({ choosenDate, setChoosenDate }: IntroProps) {
  useAnalytics("intro");
  const { adminName } = useParams();
  const calendarInfoCtx = useContext(CalendarInfoContext);
  const [adminMode, setAdminMode] = useState<boolean>(false); // admin will see more information
  const [displayHours, setDisplayHours] = useState<string[] | null>(null); // hours available for specific day
  const [isLoadingCalendarInfo, setIsLoadingCalendarInfo] =
    useState<boolean>(true); // loading indicator state
  const [calendarErrored, setCalendarErrored] = useState<boolean>(false);
  const [metamaskBrowserPopUp, setMetamaskBrowserPopUp] =
    useState<boolean>(false);

  useEffect(() => {
    metaPixelLandedOnIntroPage();
    if (!isMobile) return;
    if (isAdmin(adminName || "") || window?.ethereum) return;
    else setMetamaskBrowserPopUp(true);
  }, []);

  useEffect(() => {
    const f = async () => {
      try {
        setAdminMode(isAdmin(adminName || ""));
        setIsLoadingCalendarInfo(true);
        await calendarInfoCtx.fetchCalendarInformation(adminName || ""); // general calendar information
        setIsLoadingCalendarInfo(false);
        updateGoogleCalendarBookings(adminName || "");
        await getAllSlotsForDate(adminName, choosenDate, setDisplayHours);
      } catch (err: any) {
        setCalendarErrored(true);
      }
    };
    f();
  }, []);

  useEffect(() => {
    getAllSlotsForDate(adminName, choosenDate, setDisplayHours);
  }, [choosenDate, adminName]);

  function onTimeClick(time: string, choosenDate: Date) {
    choosenDate.setHours(Number(time.slice(0, 2)));
    choosenDate.setMinutes(Number(time.slice(3, 5)));
    choosenDate.setSeconds(0);
    choosenDate.setMilliseconds(0);
  }
  return (
    <NotFoundWrapper shouldDisplay404={calendarErrored}>
      {metamaskBrowserPopUp && (
        <GoToMetamaskBrowser
          setMetamaskBrowserPopUp={setMetamaskBrowserPopUp}
          redirectLink={`${METAMASK_DEEP_LINK}${adminName}`}
        />
      )}
      <div className="flex flex-col items-center justify-center md:gap-5">
        <div className="flex  w-full">
          <div className="flex-grow">
            {!isMobile && (
              <SearchBar
                wrapperStyle=" hidden md:flex "
                inputStyle=" w-[60%] py-[11px] "
                placeholder="Search for the events.."
              />
            )}
          </div>
          <div className="flex flex-row gap-x-[10px]">
            {!localStorage.getItem("NT_TOKEN") && (
              <Header
                showEvents={false}
                showCopyLink={false}
                showSignOut={false}
                showSignIn={true}
                adminName={
                  getTokenData(getLocalStorage("NT_TOKEN") || "")?.adminName
                }
                showMyCalendar={!adminMode}
              />
            )}
            {localStorage.getItem("NT_TOKEN") && (
              <Header
                showEvents={true}
                showCopyLink={
                  getTokenData(getLocalStorage("NT_TOKEN") || "").adminName ===
                  adminName
                }
                showSignOut={true}
                showSignIn={false}
                adminName={
                  getTokenData(getLocalStorage("NT_TOKEN") || "").adminName
                }
                showMyCalendar={adminMode}
              />
            )}
          </div>
        </div>
        {isMobile && (
          <SearchBar
            wrapperStyle="flex md:hidden mx-auto"
            inputStyle="ml-[-4px] py-[15px] w-[80%] "
            placeholder="Search for the events.."
          />
        )}
        <div className="form-intro-wrap-nt xs:overflow-auto lg:overflow-hidden">
          {isLoadingCalendarInfo ? (
            <div className="flex-[4_1_] border-r-[2px] border-[#7c7c7c62]">
              <div className="flex justify-center items-center p-6 h-full">
                <Spinner styleSize="!w-14 !h-14" />
              </div>
            </div>
          ) : (
            <div className={`${adminMode ? "w-full" : " flex-[2_1_]"}`}>
              <ActivityDescription mode={"intro"} admin={adminMode} />
              <div className="pb-[4%] flex items-center">
                {adminMode && (
                  <BlueBtn
                    title="Copy your booking link"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${FRONTEND_CONFIG.URL}/${calendarInfoCtx.calendarInformation.adminName.value}`
                      );
                    }}
                    className="py-4 mx-auto"
                  />
                )}
              </div>
            </div>
          )}
          {!adminMode && (
            <div className="border-[#7c7c7c62] border-b-[2px] lg:hidden w-full"></div>
          )}
          {!isLoadingCalendarInfo && !adminMode && (
            <div className="flex-[5_1_] flex flex-col md:flex-row">
              <div className="md:flex-[4_1_] flex flex-col gap-6 lg:gap-0">
                <h1 className="flex-initial mt-[40px] text-center text-[20px] font-bold">
                  Select Date and Time
                </h1>
                <div className="flex mx-auto items-center justify-center md:hidden ">
                  <ReactDatePicker
                    selected={choosenDate}
                    onChange={setChoosenDate}
                    className="text-center border-[#525252] border-b-[2px] cursor-pointer"
                    minDate={new Date()}
                  />
                </div>
                <div className="lg:flex-1 hidden md:flex items-center justify-center  ">
                  <ReactDatePicker
                    selected={choosenDate}
                    onChange={setChoosenDate}
                    inline
                    minDate={new Date()}
                  />
                </div>
              </div>
              <HoursGuestViewMain
                adminMode={adminMode}
                displayHours={displayHours || []}
                choosenDate={choosenDate}
                onTimeClick={onTimeClick}
                setMetamaskBrowserPopUp={setMetamaskBrowserPopUp}
              />
            </div>
          )}
        </div>
        <div className="text-center mx-auto mb-4">
          <Link className="text-[#757474] hover:text-black" to="/privacy">
            Privacy policy
          </Link>
          <span></span>
        </div>
      </div>
    </NotFoundWrapper>
  );
}
