import {
  SetStateAction,
  useEffect,
  useContext,
  RefObject,
  Fragment,
} from "react";
import { useParams } from "react-router-dom";
import Card from "../UI/Card";
import CalendarInfoContext from "../../store/calendarInformationContext";
import { confirmPaymentTransaction } from "../functions/paymentFunctions";
import { useAnalytics } from "../../hooks/useAnalytics";
import { TRANSAK_API_KEY } from "../../constants";

export interface PaymentMethodProps {
  setAllowSubmit: (arg: SetStateAction<boolean>) => void;
  setChoosenMethod: (arg: SetStateAction<boolean>) => void;
  setLoading: (arg: SetStateAction<boolean>) => void;
  ethPrice: string;
  setTransactionID: (arg: SetStateAction<string>) => void;
  emailRef: RefObject<HTMLInputElement>;
  hour: string;
  hourUTC: string;
  UNIQUE_ID: string;
  isOpen: boolean;
}
/**
 * PaymentMethod component
 * @param param0 appropriate parameters
 * @see confirmPaymentTransaction
 * @returns
 */
export function PaymentMethod({
  setAllowSubmit,
  setChoosenMethod,
  setLoading,
  ethPrice,
  setTransactionID,
  emailRef,
  hour,
  hourUTC,
  UNIQUE_ID,
  isOpen,
}: PaymentMethodProps) {
  const eventBroadcaster = useAnalytics("payment-method");
  const { email, adminName } = useParams();

  const calendarInfoCtx = useContext(CalendarInfoContext);
  const meetingPriceInDollars =
    calendarInfoCtx.calendarInformation.meetingPriceInDollars.value;
  const walletId = calendarInfoCtx.calendarInformation.walletId.value;

  useEffect(() => {
    if (email) calendarInfoCtx.fetchCalendarInformation(email);
  }, []);

  const paymentHandler = (mode: "eth" | "usdc") => {
    confirmPaymentTransaction(
      mode,
      walletId,
      meetingPriceInDollars,
      ethPrice,
      setLoading,
      setTransactionID,
      setAllowSubmit,
      setChoosenMethod,
      eventBroadcaster,
      hour,
      hourUTC
    );
  };

  return (
    <Fragment>
      <div
        className={`bg-[#000000e3] fixed top-0 left-0 w-full h-screen z-10 ${
          isOpen
            ? "opacity-[1] translate-y-[0%]"
            : "opacity-[0] translate-y-[-100%]"
        }`}
      />
      <div
        className={`fixed w-[90%] md:w-2/5 h-[400px] overflow-hidden left-[5%]  top-[20%] md:left-[30%] z-[100] ${
          isOpen
            ? "opacity-[1] translate-y-[0%]"
            : "opacity-[0] translate-y-[-100%]"
        } transition-all duration-500`}
      >
        <Card>
          <button
            className="bi bi-x-lg absolute right-[10px] top-[5px] cursor-pointer font-bold text-[16px]"
            onClick={() => setChoosenMethod(false)}
          ></button>
          <h1 className="text-center font-bold lg:text-[22px] mb-8">
            Please choose a payment method
          </h1>
          <div className="grid grid-cols-3 gap-3">
            <p className="text-center">{ethPrice} Eth</p>
            <p className="text-center">{meetingPriceInDollars} USDC</p>
            <p className="text-center">{meetingPriceInDollars} USD</p>
          </div>
          <div className="flex flex-row justify-between gap-3 my-5">
            <button
              className="btn-nt bg-[#118071] hover:bg-[#21c7b1] hover:shadow-lg w-[45%]"
              onClick={() => paymentHandler("eth")}
            >
              Ether
            </button>
            <button
              className="btn-nt bg-[#118071] hover:bg-[#21c7b1] hover:shadow-lg w-[45%]"
              onClick={() => paymentHandler("usdc")}
            >
              USDC
            </button>
            <button
              className="btn-nt bg-[#118071] hover:bg-[#21c7b1] hover:shadow-lg w-[45%]"
              onClick={() => {
                const y =
                  window.top !== null
                    ? window.top.outerHeight / 2 + window.top.screenY - 800 / 2
                    : 0;
                const x =
                  window.top !== null
                    ? window.top.outerWidth / 2 + window.top.screenX - 480 / 2
                    : 0;
                let newWin = window.open(
                  `https://global.transak.com/?apiKey=${TRANSAK_API_KEY}&redirectURL=https://crypto-back.novelty.today/fiat/payment-status&cryptoCurrencyList=ETH&defaultCryptoCurrency=ETH&network=ethereum&cryptoCurrencyCode=ETH&fiatCurrency=USD&fiatAmount=${parseInt(
                    calendarInfoCtx.calendarInformation.meetingPriceInDollars
                      .value
                  )}&walletAddress=${
                    calendarInfoCtx.calendarInformation.walletId.value
                  }&disableWalletAddressForm=true&exchangeScreenTitle=Calendar%20Novelty%20Today&isFeeCalculationHidden=true&partnerOrderId=${adminName}&partnerCustomerId=${UNIQUE_ID}`,
                  "_blank",
                  `popup,left=${x},top=${y},width=400,height=800`
                );
                if (newWin) newWin.focus();
              }}
            >
              Fiat
            </button>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
