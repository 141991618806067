import axios from "axios";
import { createContext, FC, ReactNode, SetStateAction, useState } from "react";
import { getCalendarInfo } from "../Api";
import {
  CalendarInformationInterface,
  emptyCalendarInformation,
} from "../components/interfaces/CalendarInformationInterface";
import { API_CONFIG } from "../constants";


// Calendar Info Context - Stores information related to the Pages: Intro and Form;
export interface CalendarInfoContextInterface {
  calendarInformation: CalendarInformationInterface;
  fetchCalendarInformation: (email: string) => Promise<void>;
  sendCalendarInformation: () => void;
  updateCalendarInformationValue: (
    event: any,
    propertyName:
      | "adminName"
      | "calendarName"
      | "meetingLink"
      | "walletId"
      | "meetingPriceInDollars"
      | "meetingDuration"
  ) => void;
  updateCalendarInformationState: (
    updateState: "allow" | "forbid",
    propertyName:
      | "adminName"
      | "calendarName"
      | "meetingLink"
      | "walletId"
      | "meetingPriceInDollars"
      | "meetingDuration"
  ) => void;
  email: string;
  setEmail: (arg: SetStateAction<string>) => void;
  setCalendarInformation: (
    arg: SetStateAction<CalendarInformationInterface>
  ) => void;
}

const CalendarInfoContext = createContext<CalendarInfoContextInterface>({
  calendarInformation: emptyCalendarInformation,
  fetchCalendarInformation: async (email: string) => {},
  sendCalendarInformation: () => {},
  updateCalendarInformationValue: (
    event: any,
    propertyName:
      | "adminName"
      | "calendarName"
      | "meetingLink"
      | "walletId"
      | "meetingPriceInDollars"
      | "meetingDuration"
  ) => {},
  updateCalendarInformationState: (
    updateState: "allow" | "forbid",
    propertyName:
      | "adminName"
      | "calendarName"
      | "meetingLink"
      | "walletId"
      | "meetingPriceInDollars"
      | "meetingDuration"
  ) => {},
  email: "",
  setEmail: (arg: SetStateAction<string>) => {},
  setCalendarInformation: (
    arg: SetStateAction<CalendarInformationInterface>
  ) => {},
});

type Props = {
  children?: ReactNode;
};

export const CalendarInfoContextProvider: FC<Props> = ({ children }: Props) => {
  const [calendarInformation, setCalendarInformation] =
    useState<CalendarInformationInterface>(emptyCalendarInformation);
  const [email, setEmail] = useState("");

  const fetchCalendarInfo = async (adminName: string) => {
    const responseData = await getCalendarInfo(adminName);
    setCalendarInformation(() => {
      return {
        ...emptyCalendarInformation,
        adminName: { value: responseData.adminName, isEditable: false },
        calendarName: { value: responseData.calendarName, isEditable: false },
        meetingLink: { value: responseData.meetingLink, isEditable: false },
        walletId: { value: responseData.walletId, isEditable: false },
        meetingPriceInDollars: {
          value: responseData.meetingPriceInDollars,
          isEditable: false,
        },
        availableFirst: responseData.availableFirst,
        availableLast: responseData.availableLast,
        meetingDuration: {
          value: responseData.meetingDuration,
          isEditable: false,
        },
      };
    });
    return;
  };

  const sendCalendarInformation = async () => {
    const token = localStorage.getItem("NT_TOKEN")?.toString() || "";
    const requestBody = {
      adminName: calendarInformation.adminName.value,
      calendarName: calendarInformation.calendarName.value,
      meetingPriceInDollars: calendarInformation.meetingPriceInDollars.value,
      walletId: calendarInformation.walletId.value,
      meetingLink: calendarInformation.meetingLink.value,
      meetingDuration: calendarInformation.meetingDuration.value,
    };
    
    await axios.post(
      `${API_CONFIG.URL}/updateCalendarInfo`,
      requestBody,
      {
        headers: { "X-Auth-Token": token },
      }
    );
  };

  const updateCalendarInfromationValueHandler = (
    event: any,
    propertyName:
      | "adminName"
      | "calendarName"
      | "meetingLink"
      | "walletId"
      | "meetingPriceInDollars"
      | "meetingDuration"
  ) => {
    if (propertyName === "meetingDuration") {
      setCalendarInformation((prev: CalendarInformationInterface) => {
        const newValue = (prev[propertyName]["value"] = event);
        const newObj = { ...prev, newValue };
        return { ...prev, newObj };
      });
    } else {
      setCalendarInformation((prev: CalendarInformationInterface) => {
        const newValue = (prev[propertyName]["value"] = event.target.value);
        const newObj = { ...prev, newValue };
        return { ...prev, newObj };
      });
    }
  };
  const updateCalendarInfromationStateHandler = (
    updateState: "allow" | "forbid",
    propertyName:
      | "adminName"
      | "calendarName"
      | "meetingLink"
      | "walletId"
      | "meetingPriceInDollars"
      | "meetingDuration"
  ) => {
    try {
      let updater: boolean;

      if (updateState === "allow") updater = true;
      if (updateState === "forbid") updater = false;

      setCalendarInformation((prev: CalendarInformationInterface) => {
        const newValue = (prev[propertyName]["isEditable"] = updater);
        const newObj = { ...prev, newValue };
        return { ...prev, newObj };
      });
    } catch (error) {
      console.log("descriptionStateUpdate Error:", error);
    }
  };

  const setEmailHandler = (arg: SetStateAction<string>) => {
    setEmail(arg);
  };
  const setCalendarInformationHandler = (
    arg: SetStateAction<CalendarInformationInterface>
  ) => setCalendarInformation(arg);
  return (
    <CalendarInfoContext.Provider
      value={{
        calendarInformation: calendarInformation,
        email: email,
        fetchCalendarInformation: fetchCalendarInfo,
        sendCalendarInformation: () => sendCalendarInformation(),
        setEmail: setEmailHandler,
        updateCalendarInformationValue: updateCalendarInfromationValueHandler,
        updateCalendarInformationState: updateCalendarInfromationStateHandler,
        setCalendarInformation: setCalendarInformationHandler,
      }}
    >
      {children}
    </CalendarInfoContext.Provider>
  );
};

export default CalendarInfoContext;
