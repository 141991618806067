import React from "react";
import { Disclosure } from "@headlessui/react";
import "bootstrap-icons/font/bootstrap-icons.css";

interface FAQEntryProps {
  answer: string;
  question: string;
  index: number;
  shouldDisplayBottomBorder: boolean;
}

/**
 * FAQEnty page component, shows frequently asked questions in a dropwdown
 * @returns
 */
export function FAQEnty({
  answer,
  question,
  index,
  shouldDisplayBottomBorder,
}: FAQEntryProps) {
  return (
    <div className="mt-[5px] w-[100%] px-7">
      <Disclosure
        as="div"
        className={`w-full mx-auto ${
          shouldDisplayBottomBorder ? "border-b-[1px] border-[#b6b8b8]" : ""
        }`}
      >
        {({ open }) => {
          return (
            <>
              <Disclosure.Button className="text-md font-semibold w-full">
                <div className="flex w-full justify-between text-left">
                  <p>
                    {index}) {question}
                  </p>
                  <i
                    className={`bi bi-chevron-${
                      open ? "up" : "down"
                    } font-bold`}
                  ></i>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="text-left w-full">
                <p>{answer}</p>
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
    </div>
  );
}
