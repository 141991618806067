/**
 * get time from date object
 * @param time date object to extract time from
 * @returns string in format 01:23
 */
const getTime = (time: Date) => {
  try {
    let hours = time?.getHours()?.toString();
    let minutes = time?.getMinutes()?.toString();

    if (hours?.length === 1) {
      hours = `0${time.getHours()}`;
    }
    if (minutes?.length === 1) {
      minutes = `0${time.getMinutes()}`;
    }

    return `${hours}:${minutes}`;
  } catch (error) {
    console.log(error);
    return "";
  }
};
const getTimeUTC = (time: Date) => {
  try {
    let hours = time?.getUTCHours()?.toString();
    let minutes = time?.getUTCMinutes()?.toString();

    if (hours?.length === 1) {
      hours = `0${time.getUTCHours()}`;
    }
    if (minutes?.length === 1) {
      minutes = `0${time.getUTCMinutes()}`;
    }

    return `${hours}:${minutes}`;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export { getTime, getTimeUTC };
