import { Disclosure } from "@headlessui/react";
import { ReservationAdminItem } from "../../../Api/reservations";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useRef, SetStateAction, RefObject } from "react";
const outputLocaleMeetingTime = (
  utcMeetingDate: Date,
  userTimezoneOffset: number
) => {
  return new Date(
    utcMeetingDate.getTime() - userTimezoneOffset * 60 * 1000
  ).toLocaleString("en-US", {
    timeZone: "UTC",
  });
};
export interface EventEntryProps {
  item: ReservationAdminItem;
  userTimeZoneOffset: number;
  currentRef: RefObject<HTMLDivElement> | null;
  setCurrentRef: (
    arg: SetStateAction<RefObject<HTMLDivElement> | null>
  ) => void;
}
const timeFormat = (time: string) => {
  if (time.slice(time.indexOf(" ")).indexOf(":") === 2) {
    return "0" + time.slice(time.indexOf(" ") + 1);
  }
  return time.slice(time.indexOf(" "));
};
export function EventEntry({
  item,
  userTimeZoneOffset,
  currentRef,
  setCurrentRef,
}: EventEntryProps) {
  const disclosureRef = useRef<HTMLDivElement>(null);
  return (
    <Disclosure as="div" ref={disclosureRef} className="w-full">
      {({ open, close }) => {
        if (currentRef !== null && open && currentRef !== disclosureRef)
          close(currentRef);
        return (
          <>
            <Disclosure.Button
              className="text-md font-semibold w-full"
              onClick={() => {
                setCurrentRef(disclosureRef);
              }}
            >
              <div className="flex w-full justify-between">
                <span>
                  Meeting on:{" "}
                  {timeFormat(
                    outputLocaleMeetingTime(
                      new Date(item.dateTime),
                      userTimeZoneOffset
                    )
                  )}
                </span>
                <i
                  className={`bi bi-chevron-${open ? "up" : "down"} font-bold`}
                ></i>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="text-left w-full">
              <p>Booked by: {item.userName}</p>
              <p>Email: {item.email}</p>
              <p>Additional information: {item.extraInformation}</p>
            </Disclosure.Panel>
          </>
        );
      }}
    </Disclosure>
  );
}
