/**
 * set localStorage item to specified value and dispatch "storage-custom" event
 * @param item localStorage item name
 * @param newVal new value to assign to localStorage item, if null is specified the item will be removed from localstorage
 */
export function setLocalStorage(item: string, newVal: string | null) {
  if (newVal === null) localStorage.removeItem(item);
  else localStorage.setItem(item, newVal);
  window.dispatchEvent(new Event("storage-custom"));
}
