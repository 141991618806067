import NikaKoreliPNG from "../../../assets/NikaKoreli.png";
import GeorgiKoreliPNG from "../../../assets/GeorgiKoreli.png";
import AlexanderShanPNG from "../../../assets/AlexanderShan.png";
import LinkedinIcon from "../../../assets/LinkedinIcon.png";
/**
 * Team sub component, describes Novelty Today Team
 * @returns
 */
export function Team() {
  return (
    <div className="mx-[1%] mt-[20px] py-[3%] rounded-[16px] shadow-[1px_1px_10px_0px_rgba(0,0,0,0.1)]">
      <h1 className="text-4xl font-black text-[#0B3667] text-center ">Team</h1>
      <div className="grid grid-cols-2 lg:grid-cols-3 mt-[5%] ml-[8%] md:ml-[15%] gap-x-8 md:gap-x-0 gap-y-8">
        <div className="flex flex-col md:gap-1">
          <img
            src={GeorgiKoreliPNG}
            alt="Georgi Koreli"
            className="w-[120px] lg:w-[82.5px] h-[120px] lg:h-[82.5px]  "
          />
          <div className="flex items-center ">
            <p className="text-[20px] md:text-[24px] text-[#333333] font-bold mt-3">
              Georgi Koreli
            </p>
            <a
              href="https://www.linkedin.com/in/georgi-koreli-020a7080/"
              className="cursor-pointer"
            >
              <img
                src={LinkedinIcon}
                alt="Georgi Koreli Linkedin"
                className="ml-[3px] mt-[12px]"
              />
            </a>
          </div>
          <p className="text-[14px] md:text-[16px] text-[#333333] font-bold">
            Business Development & Brand
          </p>
        </div>
        <div className="flex flex-col md:gap-1">
          <img
            src={NikaKoreliPNG}
            alt="Nika Koreli"
            className="w-[120px] lg:w-[82.5px]  h-[120px] lg:h-[82.5px]  "
          />

          <div className="flex items-center ">
            <p className="text-[20px] md:text-[24px] text-[#333333] font-bold mt-3">
              Nika Koreli
            </p>
            <a
              href="https://www.linkedin.com/in/nika-koreli-61b46217a/"
              className="cursor-pointer"
            >
              <img
                src={LinkedinIcon}
                alt="Nika Koreli Linkedin"
                className="ml-[3px] mt-[12px]"
              />
            </a>
          </div>
          <p className="text-[14px] md:text-[16px] text-[#333333] font-bold">
            Platform Development
          </p>
        </div>
        <div className="flex flex-col md:gap-1">
          <img
            src={AlexanderShanPNG}
            alt="Alexander Shan"
            className="w-[120px] lg:w-[82.5px]  h-[120px] lg:h-[82.5px]  "
          />
          <div className="flex items-center ">
            <p className="text-[20px] md:text-[24px] text-[#333333] font-bold mt-3">
              Alexander Shan
            </p>
            <a
              href="https://www.linkedin.com/in/alexander-shan-446321202/"
              className="cursor-pointer"
            >
              <img
                src={LinkedinIcon}
                alt="Alex Shan Linkedin"
                className="ml-[3px] mt-[12px]"
              />
            </a>
          </div>
          <p className="text-[14px] md:text-[16px] text-[#333333] font-bold">
            Blockchain Product Development
          </p>
        </div>
      </div>
    </div>
  );
}
