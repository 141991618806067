import axios from "axios";
import { API_CONFIG } from "../constants";

export interface SearchForCalendarsOutput {
  calendarName: string;
  meetingPriceInDollars: string;
  adminName: string;
  meetingDuration: number;
}
export async function searchForCalendars(
  keyWord: string
): Promise<SearchForCalendarsOutput[]> {
  return (await axios.get(`${API_CONFIG.URL}/searchForCalendars/${keyWord}`))
    .data;
}
