//Configuration of Project
export const MODE = process.env.NODE_ENV === "production"; // true = PRODUCTION --- false = DEVELOPMENT //

export const API_CONFIG_PRODUCTION = "https://calendar-api.novelty.today";
export const API_CONFIG_DEVELOPMENT = "http://localhost:8080";

export const API_CONFIG = {
  URL: MODE ? API_CONFIG_PRODUCTION : API_CONFIG_DEVELOPMENT,
};

const FRONTEND_CONFIG_PRODUCTION = "https://calendar.novelty.today";
const FRONTEND_CONFIG_DEVELOPMENT = "http://localhost:3000";

export const FRONTEND_CONFIG = {
  URL: MODE ? FRONTEND_CONFIG_PRODUCTION : FRONTEND_CONFIG_DEVELOPMENT,
};

export const RECEIVER_ACCOUNT = "0xeb40aad49E2C9Baa9a2829a06Fbf50f319Bbe32D";

export const generalDescription = {
  author: "Write your name",
  eventName: "Event description",
  duration: "30 min",
  meetingURL: "Write Meeting URL",
  walletId: RECEIVER_ACCOUNT,
  usdCost: 1,
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const GOOGLE_CLIENT_ID =
  "200955775213-tr1o0dv8l1iskek2oo2it54e8n28okep.apps.googleusercontent.com";
/**
 * ethereum chain id, rinkeby chain in development, ethereum mainnet in production
 */
export const CHAIN_ID = MODE ? 1 : 4;
// Ethereum mainnet chain id = 1;
// Rinkeby test network chain id = 4;

export const USDC_CONTRACT_CODE = MODE
  ? "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
  : "0x931457F4A3F349a7f791628A754Bdee4Db188ac0";

export const TRANSAK_API_KEY = "32cb51d1-d01e-4bfe-9897-015920236172";

export const GOOGLE_ANALYTICS_KEY = "UA-236049011-1";

export const METAMASK_DEEP_LINK =
  "https://metamask.app.link/dapp/calendar.novelty.today/";
