import { HeaderBtn } from "./HeaderBtn";
import { setLocalStorage } from "../../functions/localStorage/setLocalStorage";
import { useNavigate } from "react-router-dom";
import { EventsBtn } from "./EventsBtn";
import { Fragment } from "react";
import { HeaderProps } from "../../interfaces/HeaderProps";
import { getTokenData } from "../../functions/getTokenData";
import { getLocalStorage } from "../../functions/localStorage/getLocalStorage";

export default function Header({
  showSignIn,
  showSignOut,
  showEvents,
  showMyCalendar,
}: HeaderProps) {
  const navigate = useNavigate();
  return (
    <Fragment>
      {showSignIn && (
        <HeaderBtn
          text="Sign In"
          onClick={() => {
            navigate("/");
          }}
        />
      )}
      {!showMyCalendar && (
        <div className="absolute left-0 top-0">
          <HeaderBtn
            text="My Calendar"
            onClick={() => {
              navigate(
                `/${getTokenData(getLocalStorage("NT_TOKEN")!).adminName}`
              );
              document.location.reload();
            }}
          />
        </div>
      )}
      {showEvents && <EventsBtn />}
      {showSignOut && (
        <HeaderBtn
          text="Sign Out"
          onClick={() => {
            setLocalStorage("NT_TOKEN", null);
            setLocalStorage("adminEmail", null);
            navigate("/");
          }}
        />
      )}
    </Fragment>
  );
}
