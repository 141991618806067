import { useContext } from "react";
import OnboardingContext from "../../../store/onboardingContext";

export function OnboardingProgressBar() {
  const OnboardingCtx = useContext(OnboardingContext);

  return (
    <div className="flex w-[90px] rounded-[12px] border-[0.1px] border-[#dddddd]">
      <div
        className={`parallelogram h-[7px] rounded-l-xl rounded-tl-[6px] w-[24%] ${
          OnboardingCtx.onboardingStep >= 0 ? "bg-blue-500" : "bg-white"
        }`}
      ></div>
      <div className="w-[1.33%] bg-white parallelogram"></div>
      <div
        className={`parallelogram h-[7px] w-[24%] ${
          OnboardingCtx.onboardingStep >= 1 ? "bg-blue-500" : "bg-white"
        }`}
      ></div>
      <div className="w-[1.33%] bg-whiteparallelogram"></div>
      <div
        className={`parallelogram h-[7px] w-[24%] ${
          OnboardingCtx.onboardingStep >= 2 ? "bg-blue-500" : "bg-white"
        }`}
      ></div>
      <div className="w-[1.33%] bg-white parallelogram"></div>
      <div
        className={`parallelogram w-[24%] rounded-r-xl rounded-br-[6px] ${
          OnboardingCtx.onboardingStep === 3 ? "bg-blue-500" : "bg-white"
        }`}
      ></div>
    </div>
  );
}
