import { io, Socket } from "socket.io-client";
import { API_CONFIG_PRODUCTION } from "../constants";

/**
 * connects client to the server
 * @returns socket item
 */
export const setWebSocket = () => {
  const socket: Socket = io(API_CONFIG_PRODUCTION, {
    transports: ["websocket"],
  });
  return socket;
};

/**
 * listens to transaks' feed, explicitly to 'processing' status payments
 * @param socket socket
 * @param UNIQUE_ID Random ID generated in client, to differentiate orders
 * @param setChoosenMethod paymentMethod pop-ups state manager
 * @param setLoading in the form submit buttons' state manager
 */
export const transakProcessingListener = (
  socket: Socket,
  UNIQUE_ID: string,
  setChoosenMethod: (value: boolean) => void,
  setLoading: (value: boolean) => void
) => {
  socket.on("processing", (serverMessage) => {
    if (serverMessage === UNIQUE_ID) {
      setChoosenMethod(false);
      setLoading(true);
    }
  });
};

/**
 * listenst to transaks' feed, explicitly to 'completed' status payments
 * @param socket socket
 * @param UNIQUE_ID Random ID generated in client, to differentiate orders
 * @param setAllowSubmit state manager to trigger makeReservationHandler in the form;
 */
export const transakCompletionListener = (
  socket: Socket,
  UNIQUE_ID: string,
  setAllowSubmit: (value: boolean) => void
) => {
  socket.on("completed", (SERVER_UNIQUE_ID) => {
    if (SERVER_UNIQUE_ID === UNIQUE_ID) setAllowSubmit(true);
  });
};
