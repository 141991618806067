import { registerUserWithoutGoogle } from "../../Api/registerUserWithoutGoogle";

export const registerWithoutGoogleHandler = async (
  setIsCodeCheckerShown: (param: boolean) => void,
  userEmail: string
) => {
  try {
    const serverResponse = await registerUserWithoutGoogle({
      email: userEmail,
    });
    if (serverResponse?.status !== "failure") setIsCodeCheckerShown(true);
  } catch (error) {
    console.log(error);
  }
};
