import { FormEvent, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingContext from "../../store/onboardingContext";
import { Headline } from "../subComponents/Headline";
import { FirstStep } from "../subComponents/OnboardingComponents/FirstStep";
import { SecondStep } from "../subComponents/OnboardingComponents/SecondStep";
import { ThirdStep } from "../subComponents/OnboardingComponents/ThirdStep";
import { addOnboardingInfo } from "../../Api/addOnboardingInfo";
import { FourthStep } from "../subComponents/OnboardingComponents/FourthStep";
import { setLocalStorage } from "../functions/localStorage/setLocalStorage";
import { useAnalytics } from "../../hooks/useAnalytics";
import { OnboardingProgressBar } from "../subComponents/OnboardingComponents/OnboardingProgressBar";
import { metaPixelRegisteredEvent } from "../functions/metaPixelEvents";
import { ThirdStepBtns } from "../subComponents/OnboardingComponents/ThirdStepBtns";
import { SecondStepBtns } from "../subComponents/OnboardingComponents/SecondStepBtns";
import { FirstStepBtn } from "../subComponents/OnboardingComponents/FirstStepBtn";
import { FourthStepBtns } from "../subComponents/OnboardingComponents/FourthStepBtns";

/**
 * Onboarding page
 * @see FirstStep
 * @see SecondStep
 * @see ThirdStep
 * @see FourthStep
 * @returns
 */
export function Onboarding() {
  const eventBroadcaster = useAnalytics();
  const OnboardingCtx = useContext(OnboardingContext);
  const navigate = useNavigate();

  const registerUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    eventBroadcaster({
      category: "onboarding",
      action: "complete",
    });

    const userInformation = {
      adminName: OnboardingCtx.adminName,
      calendarName: OnboardingCtx.calendarName,
      meetingLink: OnboardingCtx.meetingLink,
      meetingPriceInDollars: OnboardingCtx.meetingPriceInDollars.toString(),
      walletId: OnboardingCtx.walletAddress,
      availableFirst: OnboardingCtx.availableFirst,
      availableLast: OnboardingCtx.availableLast,
      timeZoneOffset: OnboardingCtx.selectedTimezone.offset || 0,
      meetingDuration: OnboardingCtx.meetingDuration,
    };

    // add onboarding info for user
    const response = await addOnboardingInfo({
      userInformation,
    });

    setLocalStorage("NT_TOKEN", response.token);
    // if case kof success -> log in user automatically
    if (response.status === "success") {
      metaPixelRegisteredEvent();
      navigate(`/${OnboardingCtx.adminName}`);
    }
  };

  return (
    <Fragment>
      <form onSubmit={(event) => registerUserHandler(event)}>
        <Headline />
        <div className="w-[90%] xs:w-[50%] h-[75%] mx-auto mt-[2%] rounded-[12px] shadow-[0px_10px_30px_-5px_rgba(0,0,0,0.5)] overflow-auto ">
          <div className="w-full flex flex-col items-center gap-y-[10px] mt-[15px] relative">
            {OnboardingCtx.onboardingStep === 0 && <FirstStep />}
            {OnboardingCtx.onboardingStep === 1 && <SecondStep />}
            {OnboardingCtx.onboardingStep === 2 && <ThirdStep />}
            {OnboardingCtx.onboardingStep === 3 && <FourthStep />}
          </div>
        </div>
        <div className="w-[90%] xs:w-[50%] mb-[4%] flex justify-between items-center mx-auto pt-[2%]">
          <div>
            <OnboardingProgressBar />
          </div>
          {OnboardingCtx.onboardingStep === 0 && <FirstStepBtn />}
          {OnboardingCtx.onboardingStep === 1 && <SecondStepBtns />}
          {OnboardingCtx.onboardingStep === 2 && <ThirdStepBtns />}
          {OnboardingCtx.onboardingStep === 3 && <FourthStepBtns />}
        </div>
      </form>
    </Fragment>
  );
}
