declare const fbq: any; // 1. add an ambient declaration

export const metaPixelRegisteredEvent = () => {
  fbq("track", "CompleteRegistration");
};

export const metaPixelSignInPressed = () => {
  fbq("track", "SignInPressed");
};

export const metaPixelLandedOnIntroPage = () => {
  fbq("track", "LandedOnIntroPage");
};
