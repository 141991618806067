import { SetStateAction } from "react";
import { getSlots } from "../../Api/getSlotsData";

export const getAllSlotsForDate = async (
  adminName: string | undefined,
  dateTime: Date,
  setDisplayHours: (arg: SetStateAction<string[] | null>) => void
) => {
  const response = await getSlots(adminName, dateTime); // all available slots for particular date
  if (response.availableHours)
    setDisplayHours(
      response.availableHours.filter((element: string) => {
        const checkerDate = new Date(dateTime.getTime());
        checkerDate.setMilliseconds(0);
        checkerDate.setSeconds(0);
        const hour = Number(element.split(":")[0]);
        const minute = Number(element.split(":")[1]);
        checkerDate.setHours(hour);
        checkerDate.setMinutes(minute);
        return checkerDate.getTime() >= Date.now();
      })
    );
  else {
    throw new Error(`Calendar does not exists`);
  }
};
