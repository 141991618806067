import { useEffect } from "react";
import ReactGA from "react-ga";
import { GOOGLE_ANALYTICS_KEY, MODE } from "../constants";

let isGAInitialized = false;
function eventBroadcaster(event: ReactGA.EventArgs) {
  if (MODE && !isGAInitialized) {
    ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
    isGAInitialized = true;
  }
  if (MODE) ReactGA.event(event);
  /*else
    console.log(
      "%cMockGoogleAnalytics: event",
      "background: #009900; color: #ffffff",
      event
    );*/
}
export function useAnalytics(page?: string) {
  useEffect(() => {
    if (MODE && !isGAInitialized) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      isGAInitialized = true;
    }
    if (MODE && page) ReactGA.pageview(page);
    /*else if (page)
      console.log(
        "%cMockGoogleAnalytics: pageView",
        "background: #009900; color: #ffffff",
        page
      );*/
  }, []);

  return eventBroadcaster;
}
