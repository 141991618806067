import { Fragment, useContext, useState, useRef } from "react";
import { ethers } from "ethers";
import OnboardingContext from "../../../store/onboardingContext";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { checkWebProviderAndConnect } from "../../functions/makeTransactionEther";

export function FirstStep() {
  const OnboardingCtx = useContext(OnboardingContext);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useAnalytics("/onboarding/1");

  const walletAddressInput1Ref = useRef<HTMLInputElement>(null);
  const walletAddressInput2Ref = useRef<HTMLInputElement>(null);

  return (
    <Fragment>
      <div className="w-full">
        <div className="mb-[2%] flex flex-col gap-y-4">
          <h1 className="ml-[5%] text-[20px] font-bold">
            Welcome to Novelty Calendar!
          </h1>
          <p className="ml-[5%] text-[16px]">
            We take the work out of connecting with others so you can accomplish
            more.
          </p>
          <div className="w-full h-[0.1px] bg-[#dbdbdb]"></div>
        </div>
      </div>
      <label
        className="w-[90%] flex flex-col gap-y-4 font-poppinsF"
        htmlFor="meetingName"
      >
        <p className="text-[20px] font-semibold">
          Create your Novelty Calendar URL.
        </p>
        <p className="text-[16px]">
          Choose a URL that describes you or your business in a concise way.
          Make it short and easy to remember so you can share links with ease.
        </p>
        <div className="flex items-center">
          <span className="font-semibold">calendar.novelty.today/</span>
          <input
            type="text"
            placeholder="Name *"
            required
            id="name"
            className="input-nt w-[66.667%] py-3 mx-auto ml-1 "
            value={OnboardingCtx.adminName}
            onChange={(event) => {
              OnboardingCtx.setAdminName(event.target.value);
              if (OnboardingCtx.error) OnboardingCtx.setError("");
            }}
          />
        </div>
        {OnboardingCtx.error && (
          <span className="text-red-700 font-semibold px-2 text-center">
            {OnboardingCtx.error} *
          </span>
        )}
        <div className="w-full h-[0.1px] bg-[#dbdbdb]"></div>
      </label>

      <div className="w-[90%] flex flex-col m-0 mt-[1%] font-manrope text-[15px] font-semibold">
        <div className="flex flex-col gap-y-4 mb-[2%]">
          <h1 className="text-[20px] font-bold">Specify your wallet address</h1>
          <p className="text-[16px]">
            You must enter your Ethereum wallet address to get paid for hosted
            events.
          </p>
        </div>
        <div className=" hidden justify-stretch lg:flex w-full my-[1%] mb-[2.5%] ">
          <input
            type="text"
            placeholder="Your wallet address *"
            required
            id="walletId"
            className="input-nt w-full !rounded-tr-none !rounded-br-none border-r-0"
            value={OnboardingCtx.walletAddress}
            onChange={(event) => {
              OnboardingCtx.setWalletAddress(event.target.value);
              if (OnboardingCtx.walletIdValidation)
                OnboardingCtx.setWalletIdValidation("");
            }}
            onFocus={() => {
              setIsInputFocused(true);
            }}
            onBlur={() => {
              setIsInputFocused(false);
            }}
            ref={walletAddressInput1Ref}
          />
          <div
            className={`border-solid border-[1px] flex whitespace-nowrap w-[200px] border-l-0 rounded-tr-[12px] rounded-br-[12px] ${
              isInputFocused ? "border-blue-300" : "border-[gray]"
            }`}
          >
            {
              <button
                onClick={async (e) => {
                  try {
                    e.preventDefault();
                    const address = ethers.utils.getAddress(
                      await checkWebProviderAndConnect()
                    );
                    walletAddressInput1Ref.current!.value = address;
                    walletAddressInput2Ref.current!.value = address;
                    OnboardingCtx.setWalletAddress(address);
                  } catch (err) {}
                }}
                className="btn-nt bg-red-500 hover:bg-red-900 py-[5px] sm:py-[11px] min-w-[60px] !px-4"
              >
                Connect to Metamask
              </button>
            }
          </div>
        </div>
        <div className="flex flex-col gap-2 lg:hidden w-full mx-auto my-[1%] mb-[2.5%] ">
          <input
            type="text"
            placeholder="Your wallet address *"
            required
            id="walletId"
            className="input-nt w-full"
            value={OnboardingCtx.walletAddress}
            onChange={(event) => {
              OnboardingCtx.setWalletAddress(event.target.value);
              if (OnboardingCtx.walletIdValidation)
                OnboardingCtx.setWalletIdValidation("");
            }}
            ref={walletAddressInput2Ref}
          />
          <p className="text-center">or</p>
          <button
            onClick={async (e) => {
              try {
                e.preventDefault();
                const address = ethers.utils.getAddress(
                  await checkWebProviderAndConnect()
                );
                walletAddressInput1Ref.current!.value = address;
                walletAddressInput2Ref.current!.value = address;
                OnboardingCtx.setWalletAddress(address);
              } catch (err) {}
            }}
            className="btn-nt bg-red-500 hover:bg-red-900 py-[5px] sm:py-[11px] min-w-[60px]"
          >
            Connect to Metamask
          </button>
        </div>
        {OnboardingCtx.walletIdValidation && (
          <span className="text-red-700 font-semibold text-center mb-[2%]">
            {OnboardingCtx.walletIdValidation} *
          </span>
        )}
      </div>
    </Fragment>
  );
}
