import { Fragment, useContext } from "react";
import { useAnalytics } from "../../../hooks/useAnalytics";
import OnboardingContext from "../../../store/onboardingContext";

export function SecondStep() {
  const OnboardingCtx = useContext(OnboardingContext);

  useAnalytics("/onboarding/2");

  return (
    <Fragment>
      <div className="w-full">
        <div className="mb-[2%] flex flex-col gap-y-4">
          <h1 className="ml-[5%] text-[20px] font-bold">
            Create your own event!
          </h1>
          <p className="ml-[5%] text-[16px]">Enter your event details.</p>
          <div className="w-full h-[0.1px] bg-[#dbdbdb]"></div>
        </div>
      </div>
      <div className="mb-[5%] w-full flex flex-col gap-y-4 items-center">
        <label
          className="w-[60%] flex flex-col font-manrope text-[15px] font-semibold"
          htmlFor="meetingName"
        >
          <span className="text-[17px] text-[#6e6e6e]">Event name</span>
          <input
            type="text"
            placeholder="For example, startup consulting."
            id="meetingName"
            className="input-nt w-full"
            value={OnboardingCtx.calendarName}
            onChange={(event) =>
              OnboardingCtx.setCalendarName(event.target.value)
            }
          />
        </label>
        <label
          className="w-[60%] flex flex-col font-manrope text-[15px] font-semibold"
          htmlFor="meetingLink"
        >
          <span className="text-[17px] text-[#6e6e6e]">Event link</span>
          <input
            type="text"
            placeholder="https://zoom.us/j/0002566770"
            id="meetingLink"
            className="input-nt w-full"
            value={OnboardingCtx.meetingLink}
            onChange={(event) =>
              OnboardingCtx.setMeetingLink(event.target.value)
            }
          />
        </label>
        <label
          className="w-[60%] flex flex-col font-manrope text-[15px] font-semibold"
          htmlFor="price"
        >
          <span className="text-[17px] text-[#6e6e6e]">
            Event price
            <span className="text-green-700 font-bold ml-1">$</span>
          </span>
          <input
            type="string"
            placeholder="event price in USD"
            required
            id="price"
            className="input-nt w-full"
            value={
              OnboardingCtx.meetingPriceInDollars === 0
                ? ""
                : OnboardingCtx.meetingPriceInDollars
            }
            onChange={(event) => {
              let num = event.target.value.replace(/[^0-9]+/gi, "");
              OnboardingCtx.setMeetingPriceInDollars(+num);
              if (num) {
                OnboardingCtx.setMeetingPriceValidation("");
              }
            }}
          />
        </label>

        {OnboardingCtx.meetingPriceValidation && (
          <span className="text-red-700 font-semibold px-2 text-center">
            {OnboardingCtx.meetingPriceValidation}
          </span>
        )}
      </div>
    </Fragment>
  );
}
