import { Fragment, useContext } from "react";

import OnboardingContext from "../../../store/onboardingContext";
import BlueBtn from "../../UI/BlueBtn";
import TransparentBtn from "../../UI/TransparentBtn";

export function FourthStepBtns() {
  const OnboardingCtx = useContext(OnboardingContext);
  const goBackHandler = () => {
    OnboardingCtx.setOnboardingStep(2);
  };

  return (
    <Fragment>
      <div className="grid grid-cols-2 gap-x-4 mb-4">
        <TransparentBtn title="Go back" onClick={goBackHandler} />
        <BlueBtn
          btnID="registerUserBtn"
          title="Skip and register"
          typeString="submit"
        />
      </div>
    </Fragment>
  );
}
