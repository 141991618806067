import axios from "axios";
import { API_CONFIG } from "../constants";

export async function updateGoogleCalendarBookings(adminName: string) {
  return (
    await axios.post(`${API_CONFIG.URL}/updateGoogleCalendarBookings`, {
      adminName,
    })
  ).data;
}
