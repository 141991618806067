import { Fragment, useContext, useEffect, useState } from "react";
import TimezoneSelect, { ITimezoneOption } from "react-timezone-select";
import Select from "react-select";

import OnboardingContext from "../../../store/onboardingContext";
import { hoursOption } from "../../UI/multiRangeSlider/multiRangeSlider";
import { useAnalytics } from "../../../hooks/useAnalytics";

const options = [
  { value: "30", label: "30 Minutes" },
  { value: "60", label: "60 Minutes" },
  { value: "90", label: "90 Minutes" },
];

export function ThirdStep() {
  const OnboardingCtx = useContext(OnboardingContext);
  const [timezoneHelper, setTimezoneHelper] = useState<
    string | ITimezoneOption
  >(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const handleMeetingDuration = (selectedOption: any) => {
    OnboardingCtx.setMeetingDuration(parseInt(selectedOption.value));
  };

  const handleAvailabilityFirst = (selectedOption: any) => {
    OnboardingCtx.setAvailableFirst(selectedOption.value);
  };

  const handleAvailabilityLast = (selectedOption: any) => {
    OnboardingCtx.setAvailableLast(selectedOption.value);
  };

  useAnalytics("/onboarding/3");

  useEffect(() => {
    const helper = new Date();
    OnboardingCtx.setSelectedTimezone({
      abbrev: "",
      altName: "",
      label: "",
      offset: 1 * helper.getTimezoneOffset(),
      value: "",
    });
  }, []);

  return (
    <Fragment>
      <div className="w-full pb-8">
        <div className="mb-[2%] flex flex-col gap-y-4">
          <h1 className="ml-[5%] text-[20px] font-bold">
            Set your availability
          </h1>
          <p className="ml-[5%] text-[16px]">
            Let Novelty Calendar know when you’re typically available to accept
            meetings.
          </p>
          <div className="w-full h-[0.1px] bg-[#dbdbdb]"></div>
        </div>
        <div className="w-[90%] mx-auto">
          <span className="text-black font-bold">Available hours</span>
          <div className="flex justify-between w-full mt-[2%]">
            <Select
              options={hoursOption}
              className="w-[45%]"
              onChange={handleAvailabilityFirst}
              value={{
                value: `${OnboardingCtx.availableFirst}`,
                label: `${OnboardingCtx.availableFirst}`,
              }}
            />
            <Select
              options={hoursOption}
              className="w-[45%]"
              onChange={handleAvailabilityLast}
              value={{
                value: `${OnboardingCtx.availableLast}`,
                label: `${OnboardingCtx.availableLast}`,
              }}
            />
          </div>
          <div className="w-full mt-10 ">
            <span className="text-[17px] text-[#6e6e6e]">
              Choose meeting duration *
            </span>
            <Select
              options={options}
              onChange={handleMeetingDuration}
              value={{
                value: `${OnboardingCtx.meetingDuration}`,
                label: `${OnboardingCtx.meetingDuration} Minutes`,
              }}
            />
          </div>
          <div className="mt-2 mb-6">
            <span className="text-[17px] text-[#6e6e6e]">
              Choose time zone *
            </span>
            <TimezoneSelect
              value={timezoneHelper}
              onChange={(e: ITimezoneOption) => {
                let offsetInMinutes = e.offset! * -60;
                OnboardingCtx.setSelectedTimezone({
                  ...e,
                  offset: offsetInMinutes,
                });
                return setTimezoneHelper(e);
              }}
            />
          </div>
          <p
            className="text-center font-extrabold
            text-transparent bg-clip-text bg-gradient-to-r from-[#050505]  to-[#020201]"
          >
            Don’t worry! You’ll be able to further customize your availability
            later on.
          </p>
        </div>
      </div>
    </Fragment>
  );
}
