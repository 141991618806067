import { Link } from "react-router-dom";

/**
 * FooterLinks sub component, navigates to different pages
 * @returns
 */
export function FooterLinks() {
  return (
    <div className="text-center mx-auto my-10 text-[#757474]">
      <Link className="hover:text-black underline" to="/privacy">
        Privacy policy
      </Link>
      <span className="mx-1">/</span>
      <Link className="hover:text-black underline" to="/contact">
        Contact us
      </Link>
      <span className="mx-1">/</span>
      <Link className="hover:text-black underline" to="/faq">
        FAQ
      </Link>
    </div>
  );
}
