import axios from "axios";
import { API_CONFIG } from "../constants";

export interface onboardingInfoInput {
  userInformation: {
    adminName: string;
    calendarName?: string;
    meetingLink?: string;
    meetingPriceInDollars: string;
    walletId: string;
    availableFirst: string;
    availableLast: string;
    timeZoneOffset: number;
    meetingDuration: number;
  };
}

export async function addOnboardingInfo(input: onboardingInfoInput) {
  const token = localStorage.getItem('NT_TOKEN');

  return (
    await axios.post(`${API_CONFIG.URL}/addOnboardingInfo`, input, {
      headers: { "X-Auth-Token": token! },
    })
  ).data;
}
