/**
 * PaymenyTypes sub component, describes payment types
 * @returns
 */
export function PaymentTypes() {
  return (
    <div className="bg-[#d2d2d248] pb-[40px]">
      <h1 className="text-[25px] md:text-[38px] font-black text-center text-[#0B3667] pt-[40px]">
        Get paid
      </h1>
      <div className="grid gap-y-[5%] grid-cols-1 lg:grid-cols-2 mx-[20%] mt-[2%] lg:gap-x-[3%]">
        <div className="p-[16px] group hover:bg-white rounded-[8px] hover:shadow-[2px_2px_10px_0px_rgba(0,0,0,0.1)] transition duration-200">
          <i className="bi bi-coin text-[#1d67b0] text-[35px]"></i>
          <h3 className="text-[21px] font-bold mt-[5px]"> With Crypto</h3>
          <p className="text-[18px] mt-[10px]">
            Receive payments for the meetings in ETH or USDC cryptocurrency
          </p>
        </div>
        <div className="p-[16px] group hover:bg-white rounded-[8px] hover:shadow-[2px_2px_10px_0px_rgba(0,0,0,0.1)] transition duration-200">
          <i className="bi bi-cash text-[#4dc23f] text-[35px]"></i>
          <h3 className="text-[21px] font-bold mt-[5px]">With Fiat</h3>
          <p className="text-[18px] mt-[10px]">
            Receive payment for the meetings in any available fiat currency
          </p>
        </div>
      </div>
    </div>
  );
}
