import { Dispatch, Fragment, SetStateAction } from "react";
import Card from "../../UI/Card";

/**
 * GoToMetamaskBrowser component asks user wheter they want to proceed to the Metamask Browser or no
 */
interface GoToMetamaskBrowserInterface {
  setMetamaskBrowserPopUp: (param: boolean) => void;
  redirectLink: string;
}
export function GoToMetamaskBrowser({
  setMetamaskBrowserPopUp,
  redirectLink,
}: GoToMetamaskBrowserInterface) {
  const stayHandler = () => {
    setMetamaskBrowserPopUp(false);
  };

  return (
    <Fragment>
      <div className="bg-[#000000e3] fixed top-0 left-0 w-full h-screen z-10" />
      <div className="fixed w-[90%] md:w-2/5 h-[400px] overflow-hidden left-[5%] top-[20%] md:left-[30%] z-[100]">
        <Card>
          <h1 className="text-center font-robotoMono text-[16px] mx-[4%] my-[2%]">
            To book a slot continue with Metamask Browser
          </h1>
          <div className="w-full h-[1px] bg-[#8b8b8b]"></div>
          <div className="flex justify-center mt-[2%]">
            <div className="mt-[3.5%] grid grid-cols-2 gap-x-4 mb-4">
              <button
                type="button"
                onClick={stayHandler}
                className="font-black rounded-[6px] bg-white hover:shadow-lg text-black  py-[4px] sm:py-[10px] w-[90px] min-w-[70px] border-[0.1px] border-[#c2c2c2]"
              >
                Stay
              </button>
              <a
                href={redirectLink}
                className="btn-nt rounded-[6px] bg-black hover:bg-[#000000e7] hover:shadow-md py-[12px] w-[90px] min-w-[70px]"
              >
                Continue
              </a>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
