import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Meetings } from "../subComponents/Events/Meetings";

/**
 * Events page
 * @see Upcoming
 * @see Past
 * @returns
 */
export function Events() {
  useAnalytics("events");
  const navigate = useNavigate();
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [showPast, setShowPast] = useState(false);

  const reset = () => {
    if (showUpcoming) setShowUpcoming(false);
    if (showPast) setShowPast(false);
  };

  const eventsTabStateHandler = (setState: Function) => {
    reset();
    setState(true);
  };

  return (
    <div className="flex flex-col items-center gap-2 mx-auto mt-[2.5%]">
      <h1 className="text-[35px] font-bold">My Events</h1>
      <button
        className="rounded-[50%] h-[40px] w-[40px] drop-shadow-xl shadow-[1px_1px_10px_0px_rgba(0,0,0,0.3)] mb-[4%] hover:bg-[#afadadab] transition duration-500 absolute left-4 top-4 sm:left-10 sm:top-10"
        onClick={() => navigate(-1)}
      >
        <i className="bi bi-arrow-left flex-items-center "></i>
      </button>
      <div className="w-[90%] sm:w-[80%] min-h-[250px] my-6  bg-[#ffffff36] rounded-[12px]  shadow-[0px_10px_30px_-5px_rgba(0,0,0,0.5)] flex flex-col">
        <div className="w-full">
          <ul className="flex justify-evenly border-b-[0.1px] border-[#6768684b] text-[#636363] text-[25px]">
            <li
              onClick={() => eventsTabStateHandler(setShowUpcoming)}
              className={`cursor-pointer ${
                showUpcoming
                  ? "border-b-[2px] text-black border-[#165a9b] mb-[-0.1px]"
                  : "border-[#424242] mb-[-0.1px] hover:border-b-[2px] hover:text-black"
              }`}
            >
              Upcoming
            </li>
            <li
              onClick={() => eventsTabStateHandler(setShowPast)}
              className={`cursor-pointer ${
                showPast
                  ? "border-b-[2px] text-black border-[#165a9b] mb-[-0.1px]"
                  : "border-[#424242] mb-[-0.1px] hover:border-b-[2px] hover:text-black"
              }`}
            >
              Past
            </li>
          </ul>
        </div>
        <div className=" flex-[1_1_0] w-full h-full mt-[-4px] ">
          {showUpcoming && <Meetings meet={"upcomingMeetings"} />}
          {showPast && <Meetings meet={"pastMeetings"} />}
        </div>
      </div>
    </div>
  );
}
