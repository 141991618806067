import { NotFoundWrapperProps } from "../interfaces/NotFoundWrapperProps";
import { Link } from "react-router-dom";
import glasses from "../../assets/glasses.png";

/**
 * Conditionally display 404 page
 * @see Intro
 * @see Navigator
 * @returns if shouldDisplay404 is set to true the 404 page, if not then wrapped content
 */
export function NotFoundWrapper({
	shouldDisplay404,
	children,
}: NotFoundWrapperProps) {
	return (
		<>
			{shouldDisplay404 ? (
				<div className="flex flex-col gap-4 h-screen items-center justify-center text-center">
					<Link to="/" className="underline">
						<img src={glasses} alt="" className="mb-12" />
					</Link>
					<p className="text-4xl">
						Sorry, the page you were trying to access doesn't exist
					</p>
					<p className="text-2xl">
						<Link to="/" className="underline">
							Take me back to Novelty Calendar
						</Link>
					</p>
				</div>
			) : (
				<>{children}</>
			)}
		</>
	);
}
