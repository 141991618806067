import { ReactNode } from "react";

export interface CardProps {
  children: ReactNode;
  className?: string;
}

const Card = ({ children, className }: CardProps) => {
  return (
    <div
      className={`p-4 bg-[#f8f8fa] shadow-lg rounded-[14px] ${
        className ? className : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Card;
