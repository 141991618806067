import Card from "../../UI/Card";
import { Link } from "react-router-dom";

export function EventsBtn() {
  return (
    <div className="group">
      <Card>
        <h1 className="text-[#000000bb] text-[18px] font-semibold ">
          <Link to="/events">
            <span className="text-[18px] font-normal hover:opacity-[0.5] group-hover:animate-gt">
              My Events
            </span>
          </Link>
        </h1>
      </Card>
    </div>
  );
}
