import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initGoogleAuthentification } from "../functions/googleCommunication";
import { login } from "../../Api";
import { Headline } from "../subComponents/Headline";
import { setLocalStorage } from "../functions/localStorage/setLocalStorage";
import { getLocalStorage } from "../functions/localStorage/getLocalStorage";
import { getTokenData } from "../functions/getTokenData";
import { useAnalytics } from "../../hooks/useAnalytics";
import { registerUserWithoutGoogle } from "../../Api/registerUserWithoutGoogle";
import { checkVerificationCodeAndLogin } from "../functions/checkVerificationCodeAndLogin";
import { registerWithoutGoogleHandler } from "../functions/registerWithoutGoogleHandler";

declare var google: any;
/**
 * Login page component
 * @returns
 */
export function LogIn() {
  useAnalytics("login");
  const navigate = useNavigate();

  const [googleToken, setGoogleToken] = useState<string>("");
  const [userEmail, setUserEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeCheckerShown, setIsCodeCheckerShown] = useState(false);
  const [isCodeCorrect, setIsCodeCorrect] = useState(true);
  const [resendBtnTriggerTime, setResendBtnTriggerTime] = useState(
    Date.now() - 1000 * 20
  );

  const handleCallbackResponse = async (response: any) => {
    if (response.credential) setGoogleToken(response.credential);
  };

  useEffect(() => {
    initGoogleAuthentification(google, handleCallbackResponse); // Initiates Client and Renders Google Button
  }, []);

  const sendJWT = async () => {
    const serverResponse = await login({
      googleToken,
    });

    if (serverResponse.status === "success") {
      setLocalStorage("NT_TOKEN", serverResponse?.token);
      setLocalStorage("adminEmail", serverResponse?.email);

      if (serverResponse.finishedOnboarding)
        navigate(`/${serverResponse?.adminName}`);
      else navigate(`/onboarding`);
    }
  };

  useEffect(() => {
    // 1) We do not have access token, we will need to obtain it.
    if (!getLocalStorage("NT_TOKEN")) {
      if (googleToken?.length > 0) sendJWT();
    } else {
      // 2) We have token, so we go to Intro
      navigate(
        `/${getTokenData(getLocalStorage("NT_TOKEN") || "")?.adminName}`
      );
    }
  }, [googleToken]);

  useEffect(() => {
    setTimeout(() => {
      setResendBtnTriggerTime(Date.now() - 20 * 1000);
    }, 20000);
  }, [resendBtnTriggerTime]);

  useEffect(() => {
    checkVerificationCodeAndLogin(
      userEmail,
      verificationCode,
      navigate,
      setIsCodeCorrect
    );
    if (verificationCode.length !== 0) {
      setIsCodeCorrect(true);
    }
  }, [verificationCode]);

  return (
    <Fragment>
      <div className="flex flex-col items-center gap-2 mx-auto ">
        <Headline
          onClick={() => {
            navigate("/");
          }}
        />
      </div>

      {!isCodeCheckerShown && (
        <div>
          <p className="text-center mt-[2%] text-[#1D344B] text-[24.8px] font-bold tracking-wide">
            Get started today
          </p>
          <div className="bg-[rgba(149,149,149,0.03)] border-[2px] rounded-[8px] shadow-[2px_2px_25px_-5px_rgba(0,0,0,0.2)] w-[90%] xs:w-[530px] mt-[2%] mx-auto flex items-center justify-center outline-[1px] outline-[rgba(0, 0, 0, 0.5)] flex-col">
            <div className="flex flex-col items-center mt-4 w-full">
              <input
                type="text"
                placeholder="Enter your email"
                id="email"
                className="input-nt w-[90%] xs:w-[434px] rounded-[5px] placeholder:font-semibold focus:outline-transparent focus:border-black h-[64px]"
                value={userEmail}
                onChange={(event) => setUserEmail(event.target.value)}
              />
              <button
                className="w-[90%] xs:w-[434px] rounded-[5px] bg-[#0066FF] text-white h-[64px] font-bold mt-4 text-[18px]"
                type="button"
                onClick={() =>
                  registerWithoutGoogleHandler(setIsCodeCheckerShown, userEmail)
                }
              >
                Get Started
              </button>
            </div>
            <div className="font-robotoMono w-full items-center gap-x-2 flex my-4">
              <div className="flex-1 h-[1px] bg-[#E5E5E5] "></div>
              <div className="font-poppins text-[#666666]">OR</div>
              <div className="flex-1 h-[1px] bg-[#E5E5E5] "></div>
            </div>
            <div className="my-4 mt-0">
              <div id="signInDiv"></div>
            </div>
          </div>
        </div>
      )}
      {isCodeCheckerShown && (
        <div className="flex flex-col items-center">
          <p className="text-center mt-[2%] text-[#363636] text-[12px] md:text-[20px] xs:text-[18.8px] font-semibold tracking-wide mx-[10%] md:mx-auto">
            Before continuing, we need to verify your email address. Please
            check your inbox for verification code.
          </p>
          <p className="text-center mt-[1.5%] text-[#434344] text-[10px] md:text-[13px] xs:text-[14.8px] tracking-wide mx-[10%] md:mx-auto">
            If you do not receive the email at {userEmail} within several
            minutes, we can
            <span
              className={`text-blue-600 ${
                Date.now() - resendBtnTriggerTime >= 1000 * 15
                  ? "cursor-pointer hover:underline"
                  : ""
              } ml-1`}
              onClick={async () => {
                if (Date.now() - resendBtnTriggerTime >= 1000 * 15) {
                  await registerUserWithoutGoogle({ email: userEmail });
                  setResendBtnTriggerTime(Date.now());
                }
              }}
            >
              {Date.now() - resendBtnTriggerTime >= 1000 * 15 ? (
                <>resend it to you.</>
              ) : (
                <>sent</>
              )}
            </span>
          </p>
          <input
            type="text"
            placeholder=""
            id="email"
            className="mt-[1.5%] input-nt w-[60px] h-8 rounded-[0px] focus:outline-transparent focus:border-black border-0 border-b-[2px] text-center"
            value={verificationCode}
            onChange={(event) => {
              if (event.target.value.length <= 4) {
                setVerificationCode(event.target.value);
              }
            }}
          />
          {!isCodeCorrect && (
            <div className="text-red-600 mt-[0.2%] opacity-1 transition-opacity duration-1000">
              incorrect code
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
