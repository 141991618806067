import { SetStateAction, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Form } from "./pages/Form";
import { Intro } from "./pages/Intro";
import { LogIn } from "./pages/LogIn";
import { Onboarding } from "./pages/Onboarding";
import { OnboardingContextProvider } from "../store/onboardingContext";
import { Events } from "./pages/Events";
import { Privacy } from "./pages/Privacy";
import { Guest } from "./pages/Guest";
import { ContactUs } from "./pages/ContactUs";
import { FAQ } from "./pages/FAQ";
import { NotFoundWrapper } from "./wrappers/NotFoundWrapper";
/**
 * Navigation component, contains routes for pages
 * @returns
 */
export function Navigation() {
  // widely used state - to track choosen slots' exact date && time; initial value current time of the user
  const [choosenDate, setChoosenDate] = useState<Date>(new Date());

  return (
    <Routes>
      <Route path="/" element={<Guest />} />
      <Route path="/signin" element={<LogIn />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/faq" element={<FAQ />} />
      <Route
        path="/onboarding"
        element={
          <OnboardingContextProvider>
            <Onboarding />
          </OnboardingContextProvider>
        }
      />
      <Route
        path="/:adminName"
        element={
          <Intro
            choosenDate={choosenDate}
            setChoosenDate={(arg: SetStateAction<Date>) => {
              setChoosenDate(arg);
            }}
          />
        }
      />
      <Route
        path="/:adminName/choose"
        element={<Form choosenDate={choosenDate} />}
      />
      <Route path="/events" element={<Events />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<NotFoundWrapper shouldDisplay404 />} />
    </Routes>
  );
}
