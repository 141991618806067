import axios from "axios";
import { API_CONFIG } from "../constants";

export async function checkLoginVerificationCode(requestBody: {
  email: string;
  verificationCode: string;
}) {
  return (
    await axios.post(
      `${API_CONFIG.URL}/checkLoginVerificationCode`,
      requestBody
    )
  ).data;
}
