import { Fragment, useEffect } from "react";
import { FunctionalityDescription } from "../subComponents/Guest/FunctionalityDescription";
import { PaymentTypes } from "../subComponents/Guest/PaymentTypes";
import { PlatformDescription } from "../subComponents/Guest/PlatformDescription";
import { TryForFree } from "../subComponents/Guest/TryForFree";
import { Headline } from "../subComponents/Headline";
import { SignInBtn } from "../subComponents/Guest/SignInBtn";
import { FooterLinks } from "../subComponents/Guest/FooterLinks";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Team } from "../subComponents/Guest/Team";
import { getLocalStorage } from "../functions/localStorage/getLocalStorage";
import { useNavigate } from "react-router-dom";
import { getTokenData } from "../functions/getTokenData";

/**
 * Guest page component, description of the platform
 * @returns
 */
export function Guest() {
  const navigate = useNavigate();

  // if user is signed in, then change route to user's calendar 
  useEffect(() => {
    if (getLocalStorage("NT_TOKEN"))
      navigate(getTokenData(getLocalStorage("NT_TOKEN")!).adminName);
  }, []);

  useAnalytics("guest");
  return (
    <Fragment>
      <Headline />
      <SignInBtn />
      <PlatformDescription />
      <TryForFree />
      <FunctionalityDescription />
      <PaymentTypes />
      <Team />
      <FooterLinks />
    </Fragment>
  );
}
