import { ChangeEvent, useContext, useRef } from "react";
import CalendarInfoContext from "../../../store/calendarInformationContext";
import toast from "react-hot-toast";

export interface UpdateProps {
  updateDescriptionValueHandler: (event: ChangeEvent) => void;
  updateDescriptionState: (updateState: "forbid") => void;
  validator: (value: string) => string; // must return error message if validation fails. empty string otherwise
  text: string;
  inputType?: "number" | "text";
}
export function Update({
  updateDescriptionValueHandler,
  updateDescriptionState,
  validator,
  text,
  inputType = "text",
}: UpdateProps) {

  const calendarInfoCtx = useContext(CalendarInfoContext);
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div className="mt-4 flex items-center justify-between">
      <input
        type={inputType}
        className="input-nt border-0 border-b-[1px] rounded-[0px] p-[2px] px-[6px]"
        defaultValue={text}
        onChange={(e) => updateDescriptionValueHandler(e)}
        ref={ref}
      />
      <button
        className="p-[2px] px-[5%] bg-[#444443] text-[#bfbfbf] hover:bg-[#5d5d5b] hover:text-[#ffffff] transition duration-500 rounded-xl ml-[10%] text-[15px]"
        onClick={() => {
          const error = validator(ref.current?.value || "");
          if (error) toast.error(error);
          else if (
            inputType === "number" &&
            parseInt(ref.current?.value!) <= 0
          ) {
            toast.error("Price should be more than 0");
          } else {
            calendarInfoCtx.sendCalendarInformation();
            return updateDescriptionState("forbid");
          }
        }}
      >
        Update
      </button>
    </div>
  );
}
