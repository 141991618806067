import { HoursGuestViewMainProps } from "../../interfaces/HoursGuestViewMainProps";
import { Spinner } from "../../UI/Spinner";
import { HoursGuestView } from "./HoursGuestView";

/**
 * Component which displays the list of hours && loading && no hours available, used at intro page
 * @param param
 * @see TimeSelector
 * @returns
 */
export function HoursGuestViewMain({
  adminMode,
  displayHours,
  choosenDate,
  onTimeClick,
  customStyle,
  setMetamaskBrowserPopUp,
}: HoursGuestViewMainProps) {
  return (
    <div className="flex-[4_1_] flex  overflow-auto h-full items-center justify-center pt-[20px]">
      {displayHours !== null && displayHours.length !== 0 && (
        <div className="w-[80%] flex flex-col h-full">
          <HoursGuestView
            adminMode={adminMode}
            displayHours={displayHours}
            choosenDate={choosenDate}
            onTimeClick={onTimeClick}
            setMetamaskBrowserPopUp={setMetamaskBrowserPopUp}
          />
        </div>
      )}
      {displayHours !== null && displayHours.length === 0 && (
        <h1 className="text-[25px] font-semibold text-center">
          No available slots
        </h1>
      )}
      {displayHours == null && <Spinner styleSize="!w-[50px] !h-[50px" />}
    </div>
  );
}
