import axios from "axios";
import { API_CONFIG } from "../constants";

export interface PastReservationAdminItem {
  dateTime: string;
  userName: string;
  email: string;
  extraInformation: string;
  meetingLink: string;
}

export type PastReservationAdminOutput = PastReservationAdminItem[];

export async function pastReservationsAdmin(
  token: string
): Promise<PastReservationAdminOutput> {
  return (
    await axios.post(
      `${API_CONFIG.URL}/getPastReservations`,
      {},
      {
        headers: { "X-Auth-Token": token },
      }
    )
  ).data;
}
