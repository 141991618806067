import { Fragment, SyntheticEvent, useState } from "react";
import { contactUsAPI } from "../../Api/constactUs";
import { Headline } from "../subComponents/Headline";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useNavigate } from "react-router-dom";

/**
 * ContactUs page component, allows user to send a message to us
 * @returns
 */
export function ContactUs() {
  useAnalytics("contact-us");
  const [authorFullname, setAuthorFullname] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorMessage, setAuthorMessage] = useState("");

  const [fullnameValidation, setFullnameValidation] = useState("");
  const [messageValidation, setMessageValidation] = useState("");
  const [submitPressed, setSubmitPressed] = useState(false);

  const contactUsHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (authorFullname.trim().length === 0) {
      setFullnameValidation("Full Name can not be empty");
      return;
    }

    if (authorMessage.trim().length === 0) {
      setMessageValidation("Message can not be empty");
      return;
    }

    if (fullnameValidation || messageValidation) {
      setFullnameValidation("");
      setMessageValidation("");
    }

    const body = {
      authorFullname,
      authorEmail,
      authorMessage,
    };

    contactUsAPI(body);

    setAuthorFullname("");
    setAuthorEmail("");
    setAuthorMessage("");
    setSubmitPressed(true);
    setTimeout(() => {
      setSubmitPressed(false);
    }, 10000);
  };
  const navigate = useNavigate();
  return (
    <Fragment>
      <Headline
        onClick={() => {
          navigate("/");
        }}
      />

      <div className="flex flex-col lg:flex-row mx-[5%] mt-[5%] gap-[5%]">
        <div className="flex-[4_1_] mx-[4%]">
          <h1 className="text-[32px] font-black font-sans text-center lg:text-left text-transparent bg-clip-text bg-gradient-to-t from-[#3b5a76]  to-[#0B3558] ">
            Contact Our Team
          </h1>
          <p className="text-[#476788] leading-7 text-[17px] mt-[6%] mx-[10%] lg:mx-0 ">
            Submit your inquiry and one of our team members will reach out
            if there is a fit.
          </p>
        </div>
        <form
          className="flex-[5_1_] rounded-[12px] shadow-[1px_1px_10px_0px_rgba(0,0,0,0.1)] border-1 border-[#323334] flex flex-col items-center gap-y-[10px] py-[2%] mt-[2%] lg:mt-0 mb-[5%] lg:mb-0"
          onSubmit={(event) => contactUsHandler(event)}
        >
          <input
            type="text"
            className="w-[90%] border-[1px] border-[#b8b8b8] text-[#545c5c] placeholder:font-normal placeholder:text-[14px] font-semibold focus:outline-none  px-[15px] py-[8px] focus:border-[#0404049a] rounded-[4px]"
            placeholder="Full name"
            value={authorFullname}
            onChange={(e) => {
              setFullnameValidation("");
              setAuthorFullname(e.target.value);
            }}
          />
          {fullnameValidation && (
            <span className="text-red-700 font-semibold px-2 text-center">
              {fullnameValidation} *
            </span>
          )}

          <input
            type="email"
            className="w-[90%] border-[1px] border-[#b8b8b8] text-[#545c5c] placeholder:font-normal placeholder:text-[14px] font-semibold focus:outline-none  px-[15px] py-[8px] focus:border-[#0404049a] rounded-[4px]"
            placeholder="Your email"
            required
            value={authorEmail}
            onChange={(e) => setAuthorEmail(e.target.value)}
          />
          <textarea
            className="w-[90%] border-[1px] border-[#b8b8b8] text-[#545c5c] placeholder:font-normal placeholder:text-[14px] font-semibold focus:outline-none  px-[15px] py-[8px] focus:border-[#0404049a] rounded-[4px] min-h-[50px] h-[80px] max-h-[200px]"
            placeholder="Text message"
            value={authorMessage}
            onChange={(e) => {
              setMessageValidation("");
              setAuthorMessage(e.target.value);
            }}
          />
          {messageValidation && (
            <span className="text-red-700 font-semibold px-2 text-center">
              {messageValidation} *
            </span>
          )}
          {!submitPressed ? (
            <button className="btn-nt px-[9%] py-[2%] bg-[#1a324a] hover:bg-[#1a324a] duration-0">
              <i className="bi bi-check-circle text-green-400 mr-[15px] hidden"></i>
              Send
            </button>
          ) : (
            <button
              className="btn-nt px-[6%] py-[2%] bg-[#529f81] hover:bg-[#529f81] duration-0 cursor-not-allowed"
              disabled={submitPressed}
            >
              <i className="bi bi-check-circle text-green-400 mr-[15px]"></i>
              Sent
            </button>
          )}
        </form>
      </div>
    </Fragment>
  );
}
