import { ReservationAdminItem } from "../../Api/reservations";
import { monthNames, dayNames } from "../../constants";

/**
 * this function groups meetings according to date and time
 * @param pastOrUpcoming
 * @returns Grouped meetings
 */
export function groupedMeetings(pastOrUpcoming: ReservationAdminItem[] | null) {
  if (pastOrUpcoming !== null) {
    let meetingsDict: any = {};
    pastOrUpcoming.forEach(
      (meetingItem: { dateTime: string | number | Date }) => {
        let meetingDateTime = new Date(meetingItem.dateTime);
        let day = meetingDateTime.getDate();
        let days = dayNames[meetingDateTime.getDay()];
        let months = monthNames[meetingDateTime.getMonth()];
        let year = meetingDateTime.getFullYear();
        let dayFormatted = day > 9 ? day : "0" + day;
        let dictKey = `${days}, ${dayFormatted} ${months} ${year}`;

        if (meetingsDict[dictKey] === undefined) {
          meetingsDict[dictKey] = [meetingItem];
        } else {
          let oldArray = meetingsDict[dictKey];
          oldArray.push(meetingItem);
          meetingsDict[dictKey] = oldArray;
        }
      }
    );
    return meetingsDict;
  }
}
