import { useEffect, useState } from "react";
import {
  searchForCalendars,
  SearchForCalendarsOutput,
} from "../../Api/searchForCalendars";
import { SearchBarProps } from "../interfaces/SearchBarProps";

const SearchBar = ({
  wrapperStyle,
  inputStyle,
  placeholder,
}: SearchBarProps) => {
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [searchResults, setSearchResults] = useState<
    SearchForCalendarsOutput[] | []
  >([]);

  useEffect(() => {
    if (searchKeyWord.trim().length !== 0) {
      (async () => {
        const response = (await searchForCalendars(searchKeyWord)) || [];
        setSearchResults([...response]);
      })();
    } else {
      if (searchResults) setSearchResults([]);
    }
  }, [searchKeyWord]);

  return (
    <div className="w-full relative mb-4">
      <div
        className={`mt-[5.5px] mx-auto items-center justify-center relative ${wrapperStyle}`}
      >
        <i className="bi bi-search text-black absolute left-[12%] md:left-[28.5%] x4:left-[28.3%] xx:left-[28.1%]"></i>
        <input
          type="text"
          placeholder={placeholder}
          required
          autoComplete="off"
          className={`py-[1%] px-[6%] md:ml-[14%] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.15)] text-black rounded-tl placeholder:text-[#646464] pl-[8%] md:pl-[45px] ${
            searchResults.length !== 0 ? "border-0 rounded-tr" : "rounded"
          } focus:outline-none ${inputStyle}`}
          onChange={(event) => {
            setSearchKeyWord(event.target.value);
          }}
          value={searchKeyWord}
        />
        {searchKeyWord && (
          <i
            className="bi bi-x absolute right-[11%] md:right-[14%] cursor-pointer text-[18px] text-black font-black"
            onClick={() => setSearchKeyWord("")}
          ></i>
        )}
      </div>
      <div
        className={`absolute items-center justify-center flex flex-col  bg-[#ffffff] shadow-[0px_4px_8px___rgba(0,0,0,0.25)] ${inputStyle} py-[0px] ${wrapperStyle} px-auto left-[10%] md:left-[27%] pt-0 rounded-bl rounded-br ${
          searchResults.length !== 0
            ? "opacity-[1] visible"
            : "opacity-[0] invisible "
        } transition-all duration-200`}
      >
        {searchResults.map((element: SearchForCalendarsOutput) => {
          return (
            <a
              href={`/${element.adminName}`}
              key={element.adminName}
              className="pl-2 border-b border-[#96989b] last:border-b-0 w-full"
            >
              {element.calendarName}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SearchBar;
