import { Fragment } from "react";
import { useAnalytics } from "../../hooks/useAnalytics";
import { FAQEnty } from "../subComponents/FAQ/FAQEntry";
import { Headline } from "../subComponents/Headline";
import { useNavigate } from "react-router-dom";

const QA = [
  {
    question: "Can I pay for the booking in fiat?",
    answer:
      "It is possible to book a slot with crypto or fiat payments. Both are supported.",
  },
  {
    question: "Is it possible to pay with USDT?",
    answer: "No, only USDC and ETH are supported at the moment.",
  },
  {
    question: "How can I withdraw funds?",
    answer:
      "At the moment application is in testing mode. It will be implemented soon.",
  },
  {
    question:
      "Can I book a slot with different users on the same date and time?",
    answer: "Yes, it is possible.",
  },
  {
    question: " Can guests book a slot at the same date & time for my meeting?",
    answer: "No, this hour won't be available for the users once booked.",
  },
];

/**
 * FAQ page component, shows frequently asked questions
 * @returns
 */
export function FAQ() {
  const navigate = useNavigate();
  useAnalytics("faq");
  return (
    <Fragment>
      <Headline
        onClick={() => {
          navigate("/");
        }}
      />
      <h1 className="mt-[20px] text-[30px] font-semibold text-center text-[#26384a]">
        Frequently Asked Questions
      </h1>
      <div className="w-[80%] mx-auto rounded-[12px] shadow-[1px_1px_10px_0px_rgba(0,0,0,0.1)] border-1 border-[#323334] flex flex-col items-center gap-y-[10px] py-[2%] mt-[3%]  mb-[5%] lg:mb-0">
        <div className="w-[95%] lg:w-[70%] mx-auto rounded-[12px] shadow-[1px_1px_10px_0px_rgba(0,0,0,0.1)] border-1 border-[#323334] flex flex-col items-center gap-y-[10px] py-[2%]  mb-[5%] lg:mb-0">
          {QA.map((element, index) => (
            <FAQEnty
              key={element.question}
              question={element.question}
              answer={element.answer}
              index={index + 1}
              shouldDisplayBottomBorder={index !== QA.length - 1}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
}
