import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import glasses from "../../assets/glasses.png";
import { HeadlineProps } from "../interfaces/HeadlineProps";

/**
 *
 * @returns Headline components which includes logo and name;
 */
export function Headline({ onClick }: HeadlineProps) {
  let location = useLocation();
  return (
    <Fragment>
      <div
        className={`mt-[1.5%] font-bold text-[24px] md:text-[42px] text-center text-zinc-700 ${
          onClick ? "cursor-pointer" : ""
        }`}
      >
        <div
          onClick={onClick}
          className={`${
            location.pathname === "/" ? "w-[80%] mr-[20%]" : ""
          } md:w-full md:mx-auto flex justify-center gap-3 items-center`}
        >
          <img
            onClick={onClick}
            src={glasses}
            alt=""
            className="w-[60px] min-h-[25px] min-w-[55px] md:w-[90px] md:h-[20%] md:min-h-[30px] md:min-w-[75px]"
          />
          <span
            onClick={onClick}
            className="font-extrabold
            text-transparent bg-clip-text bg-gradient-to-r from-[#00eaff]  to-[#ff0080]
            "
          >
            Novelty Calendar
          </span>
        </div>
      </div>
    </Fragment>
  );
}
