export interface CalendarInformationInterface {
  // date: Date;
  adminName: {
    value: string;
    isEditable: boolean;
  };
  calendarName: {
    value: string;
    isEditable: boolean;
  };
  meetingDuration: {
    value: number;
    isEditable: boolean;
  };
  meetingLink: {
    value: string;
    isEditable: boolean;
  };
  walletId: {
    value: string;
    isEditable: boolean;
  };
  meetingPriceInDollars: {
    value: string;
    isEditable: boolean;
  };
  availableFirst: string;
  availableLast: string;
}

export const emptyCalendarInformation = {
  adminName: {
    value: "",
    isEditable: false,
  },
  calendarName: {
    value: "",
    isEditable: false,
  },
  meetingDuration: {
    value: 30,
    isEditable: false,
  },
  meetingLink: {
    value: "",
    isEditable: false,
  },
  walletId: {
    value: "",
    isEditable: false,
  },
  meetingPriceInDollars: {
    value: "",
    isEditable: false,
  },
  availableFirst: "",
  availableLast: "",
};
