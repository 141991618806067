import { GOOGLE_CLIENT_ID } from "../../constants";

const initGoogleAuthentification = (
  google: any,
  handleCallbackResponse: any
) => {
  try {
    if (google) {
      google!.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        size: "large",
        scope: "profile email",
        width: 160,  
        height: 50,
        longtitle: true,
        theme: "dark",
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const initGoogleAuthorization = (
  google: any,
  scope: string,
  callback: any,
  email: string
) => {
  return google.accounts.oauth2.initCodeClient({
    client_id: GOOGLE_CLIENT_ID,
    scope: scope, // ["https://www.googleapis.com/auth/calendar"]
    callback,
    ux_mode: "popup",
    hint: email,
  });
};

export { initGoogleAuthentification, initGoogleAuthorization };
