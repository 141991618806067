import { Fragment } from "react";
import sentLogo from "../../assets/success.png";

export interface FormSubmittedProps {
  onClick: () => void;
}
export function FormSubmitted({ onClick }: FormSubmittedProps) {
  return (
    <Fragment>
      <div className="bg-[#212221e5] fixed top-0 left-0 w-full h-screen z-10" />
      <div className="fixed flex justify-center items-center w-[90%] md:w-2/5 left-[5%] md:left-[30%] z-[100] md:h-[60vh] mx-auto top-[10vh] ">
        <div className="rounded-[12px] w-96 h-[280px] md:h-[420px] mt-[10%] bg-[#ffffff] flex justify-center items-center flex-col shadow-2xl">
          <img src={sentLogo} className="sm:mt-0" alt="Logo" />
          <h1 className="text-black ">Meeting has been booked successfully</h1>
          <button
            className="btn-nt bg-[#026d22] hover:bg-[#04a534] mt-[2%]"
            onClick={() => onClick()}
          >
            Click here to Continue
          </button>
        </div>
      </div>
    </Fragment>
  );
}
