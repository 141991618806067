import "bootstrap-icons/font/bootstrap-icons.css";

/**
 * FunctionalityDescription sub component, describes main functionalities of the platform
 * @returns
 */
export function FunctionalityDescription() {
  return (
    <div className="mt-[5%] pb-[40px]">
      <h1 className="text-[25px] md:text-[38px] font-black text-center text-[#0B3667]">
        We make it easy to get started
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-[2%] mt-[4%] mx-[5%] xl:mx-[13%] x3:grid-cols-4 gap-x-[20px] gap-y-[20px] ">
        <div className="min-w-[300px] p-4 border-[1px] border-[#6d6d6d3b] rounded-[6px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.1)] min-h-[190px]">
          <i className="bi bi-check2-circle text-[#1165b8] text-[23px]"></i>{" "}
          <h3 className="text-[21px] font-bold mt-[5px]">
            Create simple rules
          </h3>
          <p className="text-[18px] mt-[10px]">
            Let Novelty Calendar know your availability preferences, and it'll
            do the work for you.
          </p>
        </div>
        <div className="min-w-[300px] p-4 border-[1px] border-[#6d6d6d3b] rounded-[6px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.1)] min-h-[190px]">
          <i className="bi bi-check2-circle text-[#1165b8] text-[23px]"></i>{" "}
          <h3 className="text-[21px] font-bold mt-[5px]">Share your link</h3>
          <p className="text-[18px] mt-[10px]">
            Send guests your Novelty Calendar link.
          </p>
        </div>
        <div className="min-w-[300px] p-4 border-[1px] border-[#6d6d6d3b] rounded-[6px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.1)] min-h-[190px]">
          <i className="bi bi-check2-circle text-[#1165b8] text-[23px]"></i>{" "}
          <h3 className="text-[21px] font-bold mt-[5px]">Get booked</h3>
          <p className="text-[18px] mt-[10px]">
            They pick a time, and the event is added to your Novelty Calendar.
          </p>
        </div>
        <div className="min-w-[300px] p-4 border-[1px] border-[#6d6d6d3b] rounded-[6px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.1)] min-h-[190px]">
          <i className="bi bi-check2-circle text-[#1165b8] text-[23px]"></i>{" "}
          <h3 className="text-[21px] font-bold mt-[5px]">Get paid</h3>
          <p className="text-[18px] mt-[10px]">
            Get paid with each booked meeting.
          </p>
        </div>
      </div>
    </div>
  );
}
