import {
  SyntheticEvent,
  useEffect,
  useContext,
  useState,
  useRef,
  Fragment,
} from "react";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

import { ActivityDescription } from "../subComponents/ActivityDescription";
import { PaymentMethod } from "./PaymentMethod";
import { Spinner } from "../UI/Spinner";
import { FormInputs } from "../subComponents/FormSC/FormInputs";
import { FormSubmitted } from "../subComponents/FormSubmitted";
import { makeReservationHandler } from "../functions/makeReservationHandler";
import { useParams } from "react-router-dom";
import { FormProps } from "../interfaces/FormProps";
import CalendarInfoContext from "../../store/calendarInformationContext";
import { EventsBtn } from "../subComponents/Buttons/EventsBtn";
import { setEtherPrice } from "../functions/cryptoPrices";
import { setLocalStorage } from "../functions/localStorage/setLocalStorage";
import { HeaderBtn } from "../subComponents/Buttons/HeaderBtn";
import { useAnalytics } from "../../hooks/useAnalytics";
import { getTime, getTimeUTC } from "../functions/getExactTime";
import {
  setWebSocket,
  transakCompletionListener,
  transakProcessingListener,
} from "../../WebSocket/webSocket";

// declare socket
let socket: Socket;

// unique id for fiat payments;

export function Form({ choosenDate }: FormProps) {
  useAnalytics("form");

  const UNIQUE_ID = useRef(uuidv4());

  // booker information variables
  const userNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const extraInformationRef = useRef<HTMLTextAreaElement>(null);
  // calendar information
  const calendarInfoCtx = useContext(CalendarInfoContext);

  const { adminName: calendarOwnerName } = useParams();
  const [transactionID, setTransactionID] = useState<string>("");

  // eth price
  const [ethPrice, setEthPrice] = useState<string>("");

  //technical
  const navigate = useNavigate();

  // local UI interaction
  const [success, setSuccess] = useState<boolean>(false);
  const [choosenMethod, setChoosenMethod] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // booking submission
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (!calendarInfoCtx.calendarInformation.adminName.value)
      navigate(`/${calendarOwnerName}`);

    setEtherPrice(calendarOwnerName, setEthPrice, calendarInfoCtx);
  }, []);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (allowSubmit) {
      makeReservationHandler(
        calendarOwnerName,
        userNameRef,
        emailRef,
        extraInformationRef,
        choosenDate,
        calendarInfoCtx.calendarInformation.meetingDuration.value,
        transactionID
      );
      setSuccess(true);
    }
  }, [allowSubmit]);

  const submitHandler = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (userNameRef!.current!.value.trim().length === 0) {
      setErrorMessage("please enter a username");
      return;
    }
    setChoosenMethod(true);
  };

  useEffect(() => {
    // initialize WebSocket
    socket = setWebSocket();

    // listen to processing payments
    socket.on("connect", () => {
      transakProcessingListener(
        socket,
        UNIQUE_ID.current,
        setChoosenMethod,
        setLoading
      );

      // listen to completed payments
      transakCompletionListener(socket, UNIQUE_ID.current, setAllowSubmit);
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <div className="flex w-full justify-end gap-x-[10px]">
        {localStorage.getItem("NT_TOKEN") && <EventsBtn />}
        {localStorage.getItem("NT_TOKEN") && (
          <HeaderBtn
            text="Sign Out"
            onClick={() => {
              setLocalStorage("NT_TOKEN", null);
              setLocalStorage("adminEmail", null);
              navigate("/");
            }}
          />
        )}
      </div>
      <div className="form-intro-wrap-nt">
        {success && <FormSubmitted onClick={() => navigate(-1)} />}
        <PaymentMethod
          setAllowSubmit={setAllowSubmit}
          setChoosenMethod={setChoosenMethod}
          setLoading={setLoading}
          ethPrice={ethPrice}
          setTransactionID={setTransactionID}
          emailRef={emailRef}
          hour={getTime(choosenDate)}
          hourUTC={getTimeUTC(choosenDate)}
          UNIQUE_ID={UNIQUE_ID.current}
          isOpen={choosenMethod && !loading}
        />
        <div className="flex-[4_1_]  border-[#7c7c7c62]">
          <ActivityDescription
            mode={"form"}
            admin={false}
            choosenDate={choosenDate}
          />
        </div>
        <div className="flex-[9_1_] flex flex-col ">
          <div className="flex-[4_1_] flex flex-col ">
            <h1 className="flex-initial mt-[40px] text-center md:text-left m-4 text-[18px] font-bold sm:mx-auto lg:mx-4">
              Enter Details
            </h1>
            <form
              onSubmit={(event) => submitHandler(event)}
              className="flex flex-col gap-6 m-4 "
            >
              <FormInputs
                errorMessage={errorMessage}
                userNameRef={userNameRef}
                emailRef={emailRef}
                extraInformationRef={extraInformationRef}
              />
              <h1 className="text-[#4d4d4e] text-center md:text-left font-semibold text-[18px] sm:mx-auto lg:mx-0">
                Price of the meeting is{" "}
                <span className="text-green-700 font-bold">$</span>
                {
                  calendarInfoCtx.calendarInformation.meetingPriceInDollars
                    .value
                }
              </h1>
              <div className="flex justify-evenly  w-full lg:w-[60%] ">
                <button
                  type="submit"
                  className={`btn-nt lg:mx-auto sm:mx-0 ${
                    allowSubmit ? "bg-[#12a82b] hover:bg-[#00f128]" : ""
                  }`}
                >
                  {!allowSubmit && !loading && (
                    <div className="sm:inline  sm:mx-auto lg:mx-0">
                      Pay to book a slot
                    </div>
                  )}
                  {!allowSubmit && loading && (
                    <span className="flex items-center gap-x-2">
                      <span>Processing</span>
                      <Spinner />
                    </span>
                  )}
                  {allowSubmit && <Fragment>Form is Submitted</Fragment>}
                </button>
              </div>
            </form>
            <div className="flex-1 flex items-center justify-center "></div>
          </div>
        </div>
      </div>
    </div>
  );
}
