import { Link } from "react-router-dom";

import { metaPixelSignInPressed } from "../../functions/metaPixelEvents";
/**
 * SignInBtn sub component, navigates to signin page
 * @returns
 */
export function SignInBtn() {
  return (
    <div className="absolute right-2 top-[14px] md:right-10 md:top-9 h-[40px] md:h-[50px]">
      <Link
        onClick={() => {
          metaPixelSignInPressed();
        }}
        className="mx-auto text-[12px] py-[8px] px-[8px] md:text-[20px] rounded-[12px] h-[40px] md:h-[50px] md:py-[10px] md:px-[10px] bg-blue-600 text-white hover:bg-blue-400 font-bold duration-500"
        to={"/signin"}
      >
        Sign In
      </Link>
    </div>
  );
}
