import { useAnalytics } from "../../hooks/useAnalytics";
import { useNavigate } from "react-router-dom";
import { Headline } from "../subComponents/Headline";
import { useEffect } from "react";
/**
 * Privacy policy component
 * @returns
 */

export function Privacy() {
  useAnalytics("privacy");
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  });

  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center gap-2 mx-auto">
      <Headline
        onClick={() => {
          navigate("/");
        }}
      />
      <h1 className="text-center text-[30px] font-bold">
        Novelty Today Privacy policy
      </h1>
      <div className="mx-[20vw] md:p-4 border-[1px] border-[#6d6d6d3b] rounded-[6px] shadow-[2px_2px_10px_0px_rgba(0,0,0,0.1)] w-[90vw]">
        <div>
          <div>
            <p>
              Our Privacy Policy explains how Novelty Today collects, uses, and
              discloses information about you. The terms “Novelty Today,” “we,”
              “us,” and “our” include Novelty Today INC and our affiliates. We
              use the terms “member,” “you,” and “your” to mean any person using
              our Platform or attending related events, including any
              organization or person using the Platform on an organization’s
              behalf. This Privacy Policy applies to the processing of
              information about members and other individuals that we collect
              when you use our “Platform,” which means any website, application,
              or services we offer, or when you communicate with us. For
              information about choices you may have under this policy, please
              see “Your Choices” below. This Privacy Policy does not apply to
              the information that you may provide to third parties, such as
              members, and others with whom you may share information about
              yourself. For more information about the scope of our Privacy
              Policy, please see Section 4.5.
            </p>
            <p className="mt-2">
              To make this Privacy Policy easier to understand, we have provided
              summaries in these gray boxes. These summaries are not part of the
              official policy. Even though we have provided summaries, it is
              important for you to read the entire policy carefully. If you wish
              to access any particular section of this Privacy Policy, use the
              list below to jump to that section.
            </p>
            <ul className="list-decimal ml-4 text-[17px]">
              <li>Collection Of Information</li>
              <li>Use Of Information</li>
              <li>Sharing Of Information</li>
              <li>Additional Details</li>
              <li>Your Choices</li>
              <li>Data Rights</li>
              <li>California Privacy Rights</li>
              <li>Contact Us</li>
              <li>Collection of Information</li>
            </ul>
            <p className="mt-2 text-[17px]">
              Summary: We collect information about you when you:
            </p>
            <ul className="list-disc ml-6 mt-2 text-[17px]">
              <li>Create an account;</li>
              <li>Use the Platform;</li>
              <li>Attend an event;</li>
              <li>Visit our website; and</li>
              <li>Communicate with us.</li>
            </ul>
            <p className="mt-2">
              We also collect information, such as usage statistics, by using
              cookies, server logs, and similar technologies.
            </p>
            <p className="mt-2">
              If you elect to use certain features, we also collect information
              through other sources, such as social media services, invitations,
              and imported contacts.
            </p>
            <p className="mt-2">1.1 Information You Provide to Us</p>
            <p className="mt-2">
              We collect information that you provide directly to us. For
              example, we collect information that you provide when you create
              an account, choose interests, events, or communicate with us. The
              types of information that we may collect include identifiers, such
              as your name, username, password, email address, postal address,
              and phone number; payment and commercial information, such as
              payment method data and purchases, if any; demographic information
              and any other personal information that you choose to provide,
              such as photos, choices of interests. Certain information about
              yourself is optional to provide. Your choice of events is also
              optional, but we are required to process this information in order
              to administer your account, and to indicate that you joined
              certain events. See Section 2 to learn how we will use the
              information that you provide.
            </p>
            <p className="mt-2">
              1.2 Information We Collect Automatically When You Use the Platform
            </p>
            <p className="mt-2">
              When you use our Platform, we automatically collect internet and
              other electronic network activity information about you and other
              information, including:
            </p>
            <ul className="list-disc ml-6 mt-2 text-[17px]">
              <li>
                Log Information: We collect log information about your use of
                the Platform, including the type of browser that you use; the
                time, duration, and frequency of your access; Platform pages
                viewed; your IP address; and the page you visited before
                visiting our Platform.
              </li>
              <li>
                Device Information: We collect information about the computer or
                mobile device that you use to access our Platform, including the
                hardware model, operating system and version, unique device
                identifiers, and mobile network information.
              </li>
              <li>
                Geolocation Information: We may collect information about the
                location of your device each time you use our Platform based on
                your consent to the collection of this information, such as when
                you enable location services. For further information see . We
                may also collect information, such as your IP address, that can
                sometimes be used to approximate your location based on your
                device settings.
              </li>
            </ul>
          </div>
          <div>
            <p className="mt-2">Section 5.2</p>
            <ul className="list-disc ml-6 mt-2 text-[17px]">
              <li>
                Information Collected by Cookies and Other Tracking
                Technologies: We and our service providers collect information
                using various technologies, including cookies and pixel tags
                (which are also called clear GIFs, web beacons, or pixels).
                Cookies are small data files stored on your hard drive or in
                device memory that help us improve our Platform and your
                experience, and track usage of our Platform. Pixel tags are
                electronic images that may be used in our Platform or emails,
                and track usage of our Platform and effectiveness of
                communications. You can learn more about the types of cookies we
                and our service providers use by reading our Cookie Policy.
              </li>
              <li>
                Other Information: Based on your consent, we may collect other
                information from your device, such as photos from your camera
                roll or calendar information you want to manage via the
                Platform.
              </li>
            </ul>
            <p className="mt-2">
              1.3 Information We Collect From Other Sources
            </p>
            <p className="mt-2">
              We may collect information about you from other sources, such as
              through certain features on the Platform you elect to use. We rely
              on these third parties to either have your consent or ensure that
              they are otherwise legally permitted or required to disclose your
              information to us. Examples include:
            </p>
            <ul className="list-disc ml-6 mt-2 text-[17px]">
              <li>
                Social Media Services. If you register or log into your account
                through a third-party service, we will have access to some of
                your third-party account information from that service,
                including identifiers, such as your name and other information
                in that account, and used for purposes such as improving your
                Novelty Today recommendations. We may also have access to
                third-party account information if you use the Platform feature
                to share a link.
              </li>
              <li>
                Member Content. We may receive information about you when you or
                another member uploads photos or posts other content to the
                Platform. For further details about the rights over this
                information available to individuals, see.
              </li>
            </ul>
          </div>
          <div className=" mt-[0.5%] ">
            <div>
              <p className="mt-2">Section 6</p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  Current or Future Corporate Affiliates. We may receive
                  information about you from our current or future corporate
                  parents, subsidiaries, or affiliates.
                </li>
                <li>
                  Information From Other Third-Party Sources. In order to
                  provide you with more tailored recommendations, we may obtain
                  information about you from publicly and commercially available
                  sources, such as third-party publishers, advertising networks,
                  and service providers that we use to manage our ads on other
                  sites, and other third parties as permitted by law. For more
                  information about the data that we obtain from these
                  providers, please contact us at support@novelty.today.
                </li>
              </ul>
              <p className="mt-2">
                We may analyze and make inferences from the information we
                collect.
              </p>
              <p className="mt-2">2. **Use Of Information**</p>
              <p className="mt-2">Summary: We use information about you to:</p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  Operate, improve, promote, and protect Novelty Today and our
                  Platform;
                </li>
                <li>Communicate with you; and</li>
                <li>Personalize content.</li>
              </ul>
              <p className="mt-2">2.1 Operating our Platform</p>
              <p className="mt-2">
                We may use information about you described above for various
                business purposes related to operating our Platform, including
                to:
              </p>
              <p className="mt-2">
                1. Provide, maintain, and improve our Platform, including to
                process transactions, develop new products and services (for
                example, including you in tests or pilots of new or revised
                Platform functions, such as messaging or search functionality or
                using information about your usage of the Platform to develop
                new or revised messaging or search functionality), and manage
                the performance of our Platform;
              </p>
              <p className="mt-2">
                2. Display information about you, for instance, your list of
                interests, which will be visible on your profile;
              </p>
              <p className="mt-2">
                3. Personalize the Platform, for example, to suggest content in
                which we think you may be interested;
              </p>
              <p className="mt-2">
                4. Monitor and analyze trends, usage, and activities in
                connection with our Platform; and
              </p>
              <p className="mt-2">
                5. Detect, investigate, and prevent fraudulent transactions,
                abuse, and other illegal activities; to protect the rights,
                property, or safety of Novelty Today and others; to enforce
                compliance with our policies; and to comply with applicable law
                and government requests; and
              </p>
              <p className="mt-2">
                6. Perform accounting and administrative tasks, and enforce or
                manage legal claims.
              </p>
              <p className="mt-2">2.2 Communicating With You</p>
              <p className="mt-2">
                We may use information about you for various business purposes
                related to communicating with you, including to:
              </p>
              <p className="mt-2">
                1. Respond to your comments, questions, and requests, and
                provide customer service;
              </p>
              <p className="mt-2">
                2. Communicate with you, in accordance with your account
                settings, about products, services, and events offered by us and
                others, to provide news and information that we think will be of
                interest to you, to conduct online surveys, to contact you about
                events on our Platform that are being held near your location,
                and to otherwise communicate with you in accordance with Section
                2.3;
              </p>
              <p className="mt-2">
                3. Notify you about communications of other members, in
                accordance with the communication preferences that you indicate
                in your account; and
              </p>
              <p className="mt-2">
                4. Send you technical notices, updates, security alerts and
                support, and administrative messages.
              </p>
              <p className="mt-2">2.3 Advertising And Other Uses</p>
              <p className="mt-2">
                We may use information about you for various other business
                purposes, including to:
              </p>
              <p className="mt-2">
                1. Provide content, features, or sponsorships that match member
                profiles or interests;
              </p>
              <p className="mt-2">
                2. Facilitate contests and other promotions;
              </p>
              <p className="mt-2">
                3. Send you marketing communications, subject to applicable law;
              </p>
              <p className="mt-2">
                4. Combine with information that we collect for the purposes
                described in this Privacy Policy; and
              </p>
              <p className="mt-2">
                5. Carry out any other purposes described to you at the time
                that we collected the information.
              </p>
              <p className="mt-2">2.4 Legal Basis for Processing</p>
              <p className="mt-2">
                If you are in a jurisdiction that requires a specific legal
                basis for processing personal data, our legal basis for
                collecting and using the information described above will depend
                on the type of information and the specific context in which we
                collect it.
              </p>
              <p className="mt-2">
                If you are in a jurisdiction that requires a specific legal
                basis for processing personal data, our legal basis for
                collecting and using the information described above will depend
                on the type of information and the specific context in which we
                collect it.
              </p>
              <p className="mt-2">
                2. We also process information about you where it is in our
                legitimate interests to do so and not overridden by your rights
                (for example, in some cases for direct marketing, fraud
                prevention, network and information systems security, responding
                to your communications, the operation of networks of groups by
                the network administrators, and improving our Platform).
              </p>
              <p className="mt-2">
                3. Sometimes you provide us with sensitive information about
                you; for instance, the mere fact of joining a certain group may
                indicate information about your health, religion, political
                views, or sexual identity. Joining such groups or posting
                information on the Platform about these topics is entirely
                voluntary and, depending on the settings of the group and your
                profile (e.g., public or private), our legal basis for
                processing any sensitive information is either that you
                manifestly make it public or your explicit consent.
              </p>
              <p className="mt-2">
                4. In some cases, we may also have a legal obligation to process
                information about you, or may otherwise need the information to
                protect your vital interests or those of another person. For
                example, we may process information, if we believe doing so is
                required by, or in accordance with, applicable law, regulation,
                legal process, or audits.
              </p>
              <p className="mt-2">3. **Sharing Of Information**</p>
              <p className="mt-2">
                Summary: We may disclose personal information about you,
                including:
              </p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  to members or social media services, depending on how you
                  engage with the Platform. Information that is public on our
                  platform is accessible to others both on and off the Platform;
                </li>
                <li>
                  to our third-party service providers who help us to operate or
                  improve our Platform;
                </li>
                <li>
                  to authorities to comply with legal obligations and to protect
                  and defend our rights and property; and
                </li>
                <li>among our businesses and affiliated companies; and</li>
                <li>when you direct us to share your information.</li>
              </ul>
              <p className="mt-2">
                We may also share information in a manner that does not identify
                you, such as de-identified or aggregated information. Such
                information is not subject to this Privacy Policy.
              </p>
              <p className="mt-2">
                We do not sell or share information about you except as
                described in this Privacy Policy.
              </p>
              <p className="mt-2">3.1 Through Our Platform</p>
              <p className="mt-2">
                We share some of the information that we collect by displaying
                it on our Platform according to your member profile and account
                settings. Some information, such as username, interests are
                always public. We recommend that you check settings and what
                information will be available before joining or creating the
                activity to ensure that you are happy with the information that
                is visible to others.
              </p>
              <p className="mt-2">
                Information that is public on our Platform is visible to others
                but is not visible off the Platform and to people from other
                than your communities. We use your university email to validate
                that you belong to a certain community. People from other
                communities will not see your account and your events.
              </p>
              <p className="mt-2">3.2 With Our Service Providers</p>
              <p className="mt-2">
                We may use service providers in connection with operating and
                improving the Platform to assist with certain business purposes,
                such as payment processing, email transmission, conducting
                surveys or contests, data hosting, managing our ads, and some
                aspects of our technical and customer support. These service
                providers, which include data analytics providers, may access,
                process, and store information about you on our behalf for the
                purposes we authorize, subject to confidentiality obligations.
              </p>
              <p className="mt-2">
                3.3 Following The Law And Protecting Novelty Today
              </p>
              <p className="mt-2">
                We may access, preserve, and disclose information about you to
                government entities and other third parties, including the
                content of messages, if we believe disclosure is in accordance
                with, or required by, applicable law, regulation, legal process,
                or audits. We may also disclose information about you to protect
                the rights, property, or safety of, or prevent fraud or abuse
                of, Novelty Today or others.
              </p>
              <p className="mt-2">3.4 Social Sharing And Other Tools</p>
              <p className="mt-2">
                The Platform may offer social sharing features and other
                integrated tools which let you share actions that you take on
                our Platform with third-party services, and vice versa. Such
                features let you share information with your friends or the
                public, depending on the settings you have chosen with the
                service. The third-party services' use of the information will
                be governed by the third-parties’ privacy policies, and we do
                not control the third parties’ use of the shared data.
              </p>
              <p className="mt-2">3.5 Other Situations</p>
              <p className="mt-2">
                We may de-identify or aggregate information about you and share
                it freely, so that you can no longer be identified. Such
                information is not subject to this Privacy Policy.
              </p>
              <p className="mt-2">
                We may also share information about you with your consent or at
                your direction or where we are legally entitled to do so. For
                example, your information may be shared with activity sponsors
                and others as described to you when you book an event. Your
                information also is made available to other group members and
                activity organizers when you book and participate in an event.
              </p>
              <p className="mt-2">4. **Additional Details**</p>
              <p className="mt-2">Summary:</p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  We may allow others to use technologies that collect
                  information about you to provide analytic services and to
                  serve ads for Novelty Today. You may be able to disable the
                  collection or use of this information.
                </li>
                <li>
                  Novelty Today is based in and governed by U.S. law and we will
                  be processing, transferring, and storing information in and to
                  the U.S. and other countries.
                </li>
                <li>
                  We have implemented technical and organizational measures to
                  protect your personal information. We cannot guarantee these
                  controls will provide absolute security.
                </li>
                <li>
                  This Privacy Policy is periodically updated, and does not
                  apply to third parties.
                </li>
              </ul>
              <p className="mt-2">
                4.1 Analytics and Advertising Services Provided by Others
              </p>
              <p className="mt-2">
                We and others may use cookies, web beacons, device identifiers,
                and other technologies to collect information about your use of
                the Platform and other websites and online services. See our
                [Cookie
                Policy](https://www.notion.so/Cookie-Policy-8500ba00f8854c7e8afb73559c958461)
                for details about these technologies and the information that
                they collect, use, or share, including how you may be able to
                control or disable these services.
              </p>
              <p className="mt-2">
                4.2 Transfer Of Information To The U.S. And Other Countries
              </p>
              <p className="mt-2">
                Novelty Today is based in the United States. Information about
                you will be transferred to, and processed in, the U.S. and
                countries other than the country in which you are resident.
                These countries may have data protection laws that are different
                to the laws of your country and, in some cases, may not be as
                protective. We have taken appropriate safeguards, such as
                standard contractual clauses, to require that your information
                will remain protected in accordance with this Privacy Policy.
              </p>
              <p className="mt-2">4.3 Security</p>
              <p className="mt-2">
                We employ technical and organizational measures designed to
                protect your information that is under our control and that we
                process on your behalf from unauthorized access, collection,
                use, disclosure, copying, modification, or disposal, both during
                transmission and once we receive it. For example, we store all
                information that you provide to us on servers on which security
                controls are used. We train employees regarding our data privacy
                policies and procedures, and authorize employees to access
                personal information as required for their role. We use
                firewalls designed to protect against intruders and test for
                network vulnerabilities. However, no method of transmission over
                the internet or method of electronic storage is completely
                secure.
              </p>
              <p className="mt-2">
                Where you have a password, which enables you to use our
                services, you are responsible for keeping this password complex,
                secure, and confidential. If you would like to update or change
                your password, you may select the “Forgot your password?” link
                on the login page. You will be sent an email that allows you to
                reset your password.
              </p>
              <p className="mt-2">4.4 Data Retention</p>
              <p className="mt-2">
                We retain certain information that we collect from you while you
                visit or are a member of the Platform, and in certain cases
                where you have deleted your account, for the following business
                purposes:
              </p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>So you can use our Platform;</li>
                <li>
                  So ensure that we do not communicate with you if you have
                  asked us not to;
                </li>
                <li>
                  To better understand the traffic to our Platform so that we
                  can provide all members with the best possible experience;
                </li>
                <li>
                  To detect and prevent abuse of our Platform, illegal
                  activities and breaches of our Terms of Service; and
                </li>
                <li>
                  To comply with applicable legal, tax or accounting
                  requirements.
                </li>
              </ul>
              <p className="mt-2">4.5 Policy Scope</p>
              <p className="mt-2">
                This Privacy Policy does not apply to information that you have
                provided or made accessible to third parties, such as other
                members, including group organizers and network administrators,
                and others with whom you may share information about yourself.
                Our Platform may direct you to a third-party service, such as
                social media services, or a portion of our Platform may be
                controlled by a third party (typically through the use of a
                frame or pop-up window separate from other content on our
                Platform). Disclosure of information to these third parties is
                subject to the relevant third party’s privacy policy. We are not
                responsible for the third-party privacy policy or content, even
                if we link to those services from our Platform or if we share
                information with these third parties.
              </p>
              <p className="mt-2">
                Members, including group organizers and network administrators,
                are directly responsible for complying with all requirements of
                applicable privacy laws in connection with the information that
                they obtain and process for the purposes of managing their
                contacts, organizing and communicating with groups, or
                administering networks.
              </p>
              <p className="mt-2">4.6 Revisions To This Policy</p>
              <p className="mt-2">
                We may modify this Privacy Policy from time to time. When we do,
                we will provide notice to you by publishing the most current
                version and revising the date at the top of this page. If we
                make any material change to this policy, we will provide
                additional notice to you, such as by sending you an email or
                displaying a prominent notice on our Platform.
              </p>
              <p className="mt-2"></p>
              <p className="mt-2">5. **Your Choices**</p>
              <p className="mt-2">Summary:</p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  You can review and change information about you or deactivate
                  your account in your account settings, but we may retain
                  certain information.
                </li>
                <li>You can remove photo of you if you wish. </li>
              </ul>
              <p className="mt-2">5.1 Your Choices: Account Information</p>
              <p className="mt-2">
                You may update or correct your account information by editing
                your account settings as described in Section 6. You may
                deactivate your account by editing your account settings. You
                will also be able to choose what information others see about
                you by changing settings in your Account.
              </p>
              <p className="mt-2">5.2 Your Choices: Location Information</p>
              <p className="mt-2">
                When you first access the Platform, depending on your device
                settings, we will collect information about your location, which
                we use to make better recommendations for groups and events in
                your area, and to improve our Platform. If you do not want us to
                collect information about your location, then you can prevent
                this by taking the following steps:
              </p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  If using a mobile app, by changing the settings on your
                  device.
                </li>
                <li>
                  If using our website, your approximate location information
                  will be obtained via a cookie. Please refer to our [Cookie
                  Policy](https://www.notion.so/Cookie-Policy-8500ba00f8854c7e8afb73559c958461)
                  for additional information on how to manage our use of
                  cookies.
                </li>
              </ul>
              <p className="mt-2">
                Note that our Platform or its features may no longer function
                properly if you change your settings.
              </p>
              <p className="mt-2">
                Your mobile device settings may also give you the option to
                choose whether to allow us to view your geolocation on a
                continuous basis, only when using the app, or never. Allowing us
                to view your geolocation when you are not using the app allows
                us to provide you with recommendations on a regular basis.
                Please refer to your device’s guide for additional information
                on how to adjust location services.
              </p>
              <p className="mt-2">5.3 Your Choices: Cookies</p>
              <p className="mt-2">
                We may use and allow others to use cookies, web beacons, device
                identifiers, and other technologies to collect information about
                your use of the Platform and other websites and online services.
                See our [Cookie
                Policy](https://www.notion.so/Cookie-Policy-8500ba00f8854c7e8afb73559c958461)
                for details about these technologies and the information that
                they collect, use, or share, including how you may be able to
                control or disable these services.
              </p>
              <p className="mt-2">6. **Data Rights**</p>
              <p className="mt-2">Summary:</p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  You may have the right to exercise your data protection
                  rights, including to request that Novelty Today deletes,
                  amends, or provides you access to your information.
                </li>
                <li>
                  You have the right to opt out of receiving Novelty Today’s
                  promotional messages, but you may still receive other
                  communications.
                </li>
                <li>
                  You can complain to your local data protection authority about
                  the use of your information.
                </li>
              </ul>
              <p className="mt-2">
                We respond to all requests that we receive from individuals who
                wish to exercise their data protection rights in accordance with
                applicable data protection laws. You can contact us with your
                request by sending an email to support@novelty.today.
              </p>
              <p className="mt-2">
                Rights that you may have, depending on the jurisdiction in which
                you live, may include:
              </p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  Accessing, correcting, updating, or requesting deletion of
                  your information.
                </li>
                <li>
                  Objecting to processing of your information, asking us to
                  restrict processing of your information, or requesting the
                  portability of your information.
                </li>
                <li>
                  Withdrawing your consent at any time if we have collected and
                  processed your information with your consent. Withdrawing your
                  consent will not affect the lawfulness of any processing that
                  we conducted prior to your withdrawal, nor will it affect
                  processing of your information conducted in reliance on lawful
                  processing grounds other than consent.
                </li>
              </ul>
              <p className="mt-2">7. **California Privacy Rights**</p>
              <p className="mt-2">
                During the preceding 12 months, Novelty Today has collected,
                used, and shared the categories of personal information
                described above in this Privacy Policy. California residents
                have certain rights to access and delete their personal
                information and to request transparency about our privacy
                practices. If you would like to access or delete your
                information, or otherwise exercise your rights under the CCPA,
                please see Section 6. Once we receive your request, we may
                verify it by requesting information sufficient to confirm your
                identity, including by asking you for additional information.
                Consistent with California law, if you choose to exercise your
                rights, we will not charge you different prices or provide
                different quality of services for exercising your rights unless
                those differences are permitted by law. If you would like to use
                an agent registered with the California Secretary of State to
                exercise your rights, we may request evidence that you have
                provided such agent with power of attorney or that the agent
                otherwise has valid written authority to submit requests to
                exercise rights on your behalf.
              </p>
              <p className="mt-2">8. **Contact Us**</p>
              <p className="mt-2">Summary:</p>
              <ul className="list-disc ml-6 mt-2 text-[17px]">
                <li>
                  If you have any questions, you can contact us at
                  support@novelty.today.{" "}
                </li>
              </ul>
              <p className="mt-2">
                Under European data protection law, the controller of your
                information is Novelty Today Inc.
              </p>
              <p className="mt-2 mb-4">
                If you have any questions or complaints about this Privacy
                Policy or how we use your information, please contact
                support@novelty.today;
              </p>
              <p className="mt-2">9. **Google Calendar API**</p>
              <p className="mt-2">
                With the help of Google Calendar API, we are expanding the
                flexibility and convenience of the platform for our users.
              </p>
              <p className="mt-2">
                We use Google Calendar API for the following reasons:
              </p>
              <ul className="list-disc ml-6 mt-2 text-[17px] mb-4">
                <li>
                  To store Google Calendar events of the user to show them on
                  our platform to sync Novelty Calendar with the Google
                  Calendar.
                </li>
                <li>
                  Add new events created at Novelty Calendars platform to the
                  user's Google Calendar.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
