import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { TimeSelector } from "./TimeSelector";
import { HoursGuestViewProps } from "../../interfaces/HoursGuestViewProps";
import CalendarInfoContext from "../../../store/calendarInformationContext";

/**
 * Component which displays the list of hours, used on intro page
 * @param param0
 * @see TimeSelector
 * @returns
 */
export function HoursGuestView({
  adminMode,
  displayHours,
  choosenDate,
  onTimeClick,
  setMetamaskBrowserPopUp,
}: HoursGuestViewProps) {
  const location = useLocation();
  const { setCalendarInformation } = useContext(CalendarInfoContext);

  const navigateToHandler = (): string => {
    if (isMobile) {
      if (window?.ethereum) return `${location.pathname}/choose`;
      else return "";
    } else {
      return `${location.pathname}/choose`;
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {!adminMode &&
        displayHours.length !== 0 &&
        displayHours.map((element) => {
          return (
            <Link
              className="w-full"
              to={navigateToHandler() || ""}
              key={element}
            >
              <div
                onClick={() => {
                  if (isMobile && !window?.ethereum)
                    setMetamaskBrowserPopUp(true);
                  else
                    setCalendarInformation((prev) => {
                      let myObj = { ...prev, date: choosenDate };
                      return myObj;
                    });
                }}
                className="w-full flex-initial flex flex-col gap-y-[12px]"
              >
                <TimeSelector
                  time={element}
                  onClick={(time: string) => onTimeClick(time, choosenDate)}
                />
              </div>
            </Link>
          );
        })}
    </div>
  );
}
