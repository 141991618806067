import { useContext } from "react";
import OnboardingContext from "../../../store/onboardingContext";
import BlueBtn from "../../UI/BlueBtn";
import TransparentBtn from "../../UI/TransparentBtn";

export function SecondStepBtns() {
  const OnboardingCtx = useContext(OnboardingContext);

  const nextHandler = () => {
    try {
      if (OnboardingCtx.meetingPriceInDollars === 0) {
        OnboardingCtx.setMeetingPriceValidation(
          "Invalid meeting price, it should be more than $0"
        );
      } else {
        OnboardingCtx.setCalendarName(OnboardingCtx.calendarName.trim());
        OnboardingCtx.setMeetingLink(OnboardingCtx.meetingLink.trim());
        OnboardingCtx.setOnboardingStep(2);
      }
    } catch (err: any) {}
  };

  const goBackHandler = () => {
    OnboardingCtx.setOnboardingStep(0);
  };

  return (
    <div className="grid grid-cols-2 gap-x-4 mb-4">
      <TransparentBtn
        title="Go back"
        className="font-normal"
        onClick={goBackHandler}
      />
      <BlueBtn title="Continue" onClick={nextHandler} />
    </div>
  );
}
