import axios from "axios";
import { API_CONFIG } from "../constants";
export interface ReservationItem {
  dateTime: string;
}
export type ReservationOutput = ReservationItem[];
export async function reservations(email: string): Promise<ReservationOutput> {
  return (await axios.get(`${API_CONFIG.URL}/reservations/${email}`)).data;
}
export interface ReservationAdminItem {
  dateTime: string;
  userName: string;
  email: string;
  extraInformation: string;
  meetingLink: string;
}
export type ReservationAdminOutput = ReservationAdminItem[];

export async function reservationsAdmin(
  token: string
): Promise<ReservationAdminOutput> {
  return (
    await axios.post(
      `${API_CONFIG.URL}/getReservations`,
      {},
      {
        headers: { "X-Auth-Token": token },
      }
    )
  ).data;
}
