export interface TimeSelectorProps {
  time: string;
  onClick?: (time: string) => void;
}
export function TimeSelector({ time, onClick }: TimeSelectorProps) {
  return (
    <button
      className={`box-border cursor-pointer px-[2px] py-2 border-[1.75px] border-[#278ff07c] text-[#278ff0] font-bold rounded-[6px] hover:border-[#278ff0] transition-border duration-100 mb-[12px] bg-transparent`}
      onClick={() => onClick && onClick(time)}
    >
      {time}
    </button>
  );
}
