export interface CardProps {
  title: string;
  className?: string;
  onClick?: () => void;
  btnID?: string;
  typeString?: "button" | "submit";
}

const BlueBtn = ({
  onClick,
  title,
  className,
  typeString = "button",
  btnID,
}: CardProps) => {
  return (
    <button
      className={`cursor-pointer py-3 px-5 text-center text-[14px] tracking-wide text-white font-semibold font-poppins bg-[#0069FF] shadow-lg rounded-[40px] min-w-[80px] ${className}`}
      onClick={onClick}
      type={typeString || "button"}
      id={btnID || ""}
    >
      {title}
    </button>
  );
};

export default BlueBtn;
