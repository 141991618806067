import screenshot from "../../../assets/laptop.png";
import { SignInBtn } from "./SignInBtn";

/**
 * PlayformDescription sub component, describes main concept, and shows host-page in laptop view
 * @returns
 */
export function PlatformDescription() {
  return (
    <div className="mt-4 md:mt-0 flex flex-col justify-between items-center text-center lg:text-left lg:flex-row ">
      <div className="flex flex-col lg:ml-[4.5%] gap-5">
        <div className="text-[18px] md:text-[45px] md:mt-5 font-semibold font-poppins md:leading-[50px] text-transparent bg-clip-text bg-gradient-to-r from-[#2477d6]  to-[#963c7f]">
          <div className="text-transparent bg-clip-text bg-gradient-to-r from-[#297883]  to-[#289faf]">
            Web3 call scheduling platform.
          </div>
          <div className="md:mt-3 text-transparent bg-clip-text bg-gradient-to-r from-[#3e5aa8]  to-[#3e5aa8]">
            Use your wallet instead
          </div>
          <div className="text-transparent bg-clip-text bg-gradient-to-r from-[#3e5aa8]  to-[#416ce2]">
            of banking account.
          </div>
          <div className="md:mt-3 text-transparent bg-clip-text bg-gradient-to-r from-[#1e6b47]  to-[#044e23]">
            Get paid in crypto or fiat.
          </div>
        </div>
      </div>
      <div>
        <img
          src={screenshot}
          alt=""
          className="border-solid border-zinc-700 w-[700px]"
        />
      </div>
    </div>
  );
}
