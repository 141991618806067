import { SetStateAction } from "react";
import ReactGA from "react-ga";
import { sendTx, getTx } from "./makeTransactionEther";
import { sendUSDC } from "./makeTransactionUsdc";
import { CHAIN_ID, MODE } from "../../constants";
import { toast } from "react-hot-toast";

export const confirmPaymentTransaction = async (
  mode: "eth" | "usdc",
  walletId: string,
  meetingPriceInDollars: string,
  ethPrice: string,
  setLoading: (arg: SetStateAction<boolean>) => void,
  setTransactionID: (arg: SetStateAction<string>) => void,
  setAllowSubmit: (arg: SetStateAction<boolean>) => void,
  setChoosenMethod: (arg: SetStateAction<boolean>) => void,
  eventBroadcaster: (arg: ReactGA.EventArgs) => void,
  hour: string,
  hourUTC: string
) => {
  try {
    let hash = "";
    let body: any;

    // Step 1: sending transaction and obtaining transaction hash
    if (mode === "eth") {
      body = await sendTx(walletId, parseFloat(ethPrice));
      hash = body?.hash;
    } else if (mode === "usdc") {
      body = await sendUSDC(
        walletId,
        parseInt(meetingPriceInDollars) * 1000000
      );
      hash = body?.hash;
    }

    if (hash?.length === 0 || typeof hash === "undefined") {
      return; // transaction failed
    } else {
      // Step 2: waiting transaction confirmation
      setLoading(true);

      let transactionListener = setInterval(async () => {
        let { confirmations, chainId } = await getTx(body?.hash); //waiting confirmation
        if (confirmations !== 0) {
          clearInterval(transactionListener);
          if (chainId === CHAIN_ID) {
            setTransactionID(hash); // get hash of successful transaction
            setAllowSubmit(true); // a booking is sent to back
            setChoosenMethod(false); // hide payment popup
            eventBroadcaster({
              category: "payment-made-mode",
              action: `${mode}`,
            });
            eventBroadcaster({
              category: "payment-made-price-usd",
              action: `${meetingPriceInDollars}`,
            });
            eventBroadcaster({
              category: "booking-hour",
              action: `${hour}`,
            });
            eventBroadcaster({
              category: "booking-hour-utc",
              action: `${hourUTC}`,
            });
          } else {
            toast.error(
              `Please, pay with Ethereum ${
                MODE ? "mainnet network" : "rinkeby testnet"
              }`
            );
            setAllowSubmit(false);
            setChoosenMethod(true);
          }
          setLoading(false);
        }
      }, 3000);
    }
  } catch (err: any) {
    console.log(err);
    toast.error("something went wrong");
  }
};
