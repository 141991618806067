import axios from "axios";
import { API_CONFIG } from "../constants";

export interface GetUniqueAdminNameOutput {
  adminName: string;
}

export async function getUniqueAdminName(
  email: string,
  adminName: string
): Promise<GetUniqueAdminNameOutput> {
  return (
    await axios.get(
      `${API_CONFIG.URL}/getUniqueAdminName/${adminName}/${email}`
    )
  ).data;
}
