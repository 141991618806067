import { RefObject } from "react";
import { MakeReservationInput } from "../../Api";
import { makeReservation } from "../../Api";
import { getMyTimezone } from "../../Api/getMyTimezone";
import { getTokenData } from "./getTokenData";
import { getLocalStorage } from "./localStorage/getLocalStorage";

export const makeReservationHandler = async (
  adminName: string | undefined,
  userNameRef: RefObject<HTMLInputElement>,
  emailRef: RefObject<HTMLInputElement>,
  extraInformationRef: RefObject<HTMLTextAreaElement>,
  choosenDate: Date,
  meetingDuration: number,
  transactionID: string
) => {
  try {
    let userTimeOffset;
    const token = getLocalStorage("NT_TOKEN");
    if (!token) {
      userTimeOffset = new Date().getTimezoneOffset();
    } else {
      const adminName = getTokenData(token!).adminName;
      userTimeOffset = (await getMyTimezone(adminName || "")).timeZoneOffset;
    }

    const body: MakeReservationInput = {
      adminName: adminName,
      userName: userNameRef!.current!.value,
      userEmail: emailRef!.current!.value,
      extraInformation: extraInformationRef!.current!.value || "",
      dateTime: choosenDate.toUTCString(),
      meetingDuration,
      transactionID: transactionID || "custom",
      userTimeOffset: userTimeOffset,
    };

    await makeReservation(
      body,
      getLocalStorage("NT_TOKEN") || undefined
    );

    // cleaning up the form
    userNameRef.current!.value = "";
    emailRef.current!.value = "";
    extraInformationRef.current!.value = "";
  } catch (error) {
    console.log(error);
  }
};
